import trungVuongLogo from "assets/images/clients/trung-vuong-logo.png";
import cdnnLogo from "assets/images/clients/cdnn-logo.png";
import karateHanuLogo from "assets/images/clients/karate-hanu-logo.png";
import emonWebLogo from "assets/images/clients/emon-web-logo.png";

const clients = [
  {
    imgSrc: trungVuongLogo,
    alt: "Trung Vuong logo",
  },
  {
    imgSrc: cdnnLogo,
    alt: "CDNN logo",
  },
  {
    imgSrc: karateHanuLogo,
    alt: "Karate Hanu logo",
  },
  {
    imgSrc: emonWebLogo,
    alt: "Emon Web logo",
  },
];

export default clients;
