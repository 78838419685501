interface Highlight {
  label: string;
  value: string | number;
}

const highlight: Highlight[] = [
  { label: "Year of Web dev", value: "1+" },
  { label: "Liters of Blood donated", value: 4 },
];

export default highlight;
