const contributors: Record<string, IContributor> = {
  xoaicc: {
    image: "https://avatars.githubusercontent.com/u/88529456?v=4",
    name: "Vuong Do",
    url: "https://github.com/xoaicc"
  },
  codengoo: {
    image: "https://avatars.githubusercontent.com/u/69780921?v=4",
    name: "Đỗ Tuấn Nghĩa",
    url: "https://github.com/codengoo"
  },
  Thaife: {
    image: "https://avatars.githubusercontent.com/u/90520411?v=4",
    name: "Thaife",
    url: "https://github.com/Thaife"
  },
  dothi2k1: {
    image: "https://avatars.githubusercontent.com/u/61841231?v=4",
    name: "DoThi",
    url: "https://github.com/dothi2k1"
  },
  "chinxh-work": {
    image: "https://avatars.githubusercontent.com/u/155343204?v=4",
    name: "chinxh-work",
    url: "https://github.com/chinxh-work"
  },
  tranduchuy682: {
    image: "https://avatars.githubusercontent.com/u/55192155?v=4",
    name: "Trần Đức Huy",
    url: "https://github.com/tranduchuy682"
  },
  thuanlb: {
    image: "https://avatars.githubusercontent.com/u/56251669?v=4",
    name: "thuanlb",
    url: "https://github.com/thuanlb"
  },
  minhkonzz: {
    image: "https://avatars.githubusercontent.com/u/58776566?v=4",
    name: "minhkonzz",
    url: "https://github.com/minhkonzz"
  },
  xuanduz: {
    image: "https://avatars.githubusercontent.com/u/72388249?v=4",
    name: "xuanduz",
    url: "https://github.com/xuanduz"
  },
  phivandong: {
    image: "https://avatars.githubusercontent.com/u/81403664?v=4",
    name: "graypackidz",
    url: "https://github.com/phivandong"
  },
  KhaiHust: {
    image: "https://avatars.githubusercontent.com/u/95922706?v=4",
    name: "Khai Tran",
    url: "https://github.com/KhaiHust"
  },
  HoangCongMin: {
    image: "https://avatars.githubusercontent.com/u/101419067?v=4",
    name: "HOÀNG CÔNG MINH",
    url: "https://github.com/HoangCongMin"
  },
}

export default contributors;