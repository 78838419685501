import { CheckCircleIcon } from "lucide-react";

interface ListProps {
  type: "ol" | "ul";
  nested?: boolean;
  withIcon?: boolean;
  children: React.ReactElement[];
}

/**
 * A functional component that renders a styled ordered or unordered list.
 *
 * @param {ListProps} props - The props for this component.
 * @param {ListProps["type"]} props.type - The type of list to render. Can be either "ol" or "ul".
 * @param {ListProps["nested"]} props.nested - Whether the list is a nested list. Defaults to false.
 * @param {ListProps["withIcon"]} props.withIcon - Whether to render a checkmark icon before each list item. Defaults to false.
 * @param {ListProps["children"]} props.children - An array of React elements to be rendered as list items.
 * @returns {JSX.Element} A JSX element representing the list.
 */
export default function List({
  type,
  nested,
  withIcon,
  children,
}: ListProps): JSX.Element {
  const L: "ol" | "ul" = type;

  return (
    <L
      className={`${
        nested ? "ps-5 mt-2" : "max-w-md text-gray-500 dark:text-gray-400"
      } ${
        type === "ol" && !withIcon
          ? "list-decimal"
          : !withIcon
          ? "list-disc"
          : "list-none"
      } space-y-1 list-inside`}
    >
      {withIcon ? (
        <>
          {children.map((child, index) => (
            <li key={index} className="flex">
              <CheckCircleIcon className="w-3.5 h-3.5 mt-[5px] me-2 text-green-500 dark:text-green-400 flex-shrink-0" />
              {child}
            </li>
          ))}
        </>
      ) : (
        children
      )}
    </L>
  );
}
