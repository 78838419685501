import { useState, useEffect } from "react";

interface ITypingEffectProps {
  textList: string[];
  speed?: number;
  pause?: number;
}

export default function TypingEffect({
  textList,
  speed = 100,
  pause = 2000,
}: ITypingEffectProps) {
  const nonBreakingSpace = "\u00A0";
  const [displayedText, setDisplayedText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopNum, setLoopNum] = useState(0);

  useEffect(() => {
    const handleTyping = () => {
      const currentText = textList[loopNum % textList.length];
      const updatedText = isDeleting
        ? currentText.substring(0, displayedText.length - 1)
        : currentText.substring(0, displayedText.length + 1);

      setDisplayedText(updatedText);

      if (!isDeleting && updatedText === currentText) {
        setTimeout(() => setIsDeleting(true), pause);
      } else if (isDeleting && updatedText === "") {
        setIsDeleting(false);
        setLoopNum(loopNum + 1);
      }
    };

    const typingSpeed = isDeleting ? speed / 2 : speed;
    const timer = setTimeout(handleTyping, typingSpeed);

    return () => clearTimeout(timer);
  }, [displayedText, isDeleting, loopNum, textList, speed, pause]);

  return (
    <p className="text-xl text-gray-600">{displayedText || nonBreakingSpace}</p>
  );
}
