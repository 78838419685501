import contributors from "./contributors";
import * as images from "assets/images/projects";

const featuredProjects: IProject[] = [
  {
    title: "Nextjs Shadcn Starter Template",
    clientType: "EmonWeb Agency VN",
    description: "A starter template for next.js project with Shadcn. Contains base ui and useful components. Base features: Theme Mode, Form Validation, Multi-language Support, Search Functionality, Error Pages, SEO, etc.",
    image: "",
    url: "",
    github: "",
    technologies: ["React", "Next.js", "Shadcn", "TailwindCSS", "TypeScript", "Zod", "Lucide", "React Hook Form", "Husky", "ESLint", "Prettier"],
    contributors: [
      contributors["xoaicc"],
      contributors["minhkonzz"],
      contributors["HoangCongMin"],
    ],
    deliveryDate: "",
  },
  {
    title: "Microservices Starter Template",
    clientType: "EmonWeb Agency VN",
    description: "A starter template for microservices back-end system.",
    image: "",
    url: "",
    github: "https://github.com/EmonWeb-Agency-VN/microservices-starter-template",
    technologies: ["Node.js", "Nest.js", "MySQL", "TypeScript", "Java", "Spring Boot", "Go", "Gin", "C#", "DOTNET", "Docker"],
    contributors: [
      contributors["tranduchuy682"],
      contributors["xuanduz"],
      contributors["phivandong"],
      contributors["KhaiHust"],
      contributors["thuanlb"],
      contributors["xoaicc"],
    ],
    deliveryDate: "",
  },
  {
    title: "Reporting System web app",
    clientType: "Hanoi University's Karate club",
    description: "System for reporting and managing publishing information of publisher.",
    image: "",
    url: "",
    github: "",
    technologies: ["React", "Joy UI", "TailwindCSS", "TypeScript", "Express.js", "MySQL"],
    contributors: [
      contributors["xoaicc"],
    ],
    deliveryDate: "",
  },
  {
    title: "Karate HANU official web",
    clientType: "Hanoi University's Karate club",
    description: "Official web of Hanoi University's Karate club.",
    image: "",
    url: "https://hanukarate.club/",
    github: "https://github.com/xoaicc/karate-hanu-club",
    technologies: ["React"],
    contributors: [
      contributors["xoaicc"],
    ],
    deliveryDate: "2023-09-10",
  },
  {
    title: "Shop Acc Game web",
    description: "Web app for buying acc game online, such as: Free Fire, PUBG Mobile, AOV. Security with JWT.",
    image: "",
    url: "",
    github: "",
    technologies: ["Next.js", "TailwindCSS", "Flowbite", "TypeScript", "Express.js", "MySQL"],
    contributors: [
      contributors["xoaicc"],
      contributors["codengoo"],
      contributors["dothi2k1"],
      contributors["chinxh-work"],
    ],
    deliveryDate: "2023-09-10",
  },
  {
    title: "Game Team Finder web",
    description: "Web app for find game partners like Lita, Playduo, but more modern than. Security with JWT.",
    image: "",
    url: "",
    github: "",
    technologies: ["Next.js", "TailwindCSS", "TypeScript", "C#", "ASP.NET", "PostgreSQL"],
    contributors: [
      contributors["xoaicc"],
      contributors["codengoo"],
      contributors["Thaife"],
      contributors["dothi2k1"],
      contributors["chinxh-work"],
    ],
    deliveryDate: "",
  },
  {
    title: "CDNN Admission landing",
    clientType: "Hanoi College of Foreign Languages",
    description: "Landing page for Admission of Hanoi College of Foreign Languages.",
    image: "",
    url: "",
    github: "",
    technologies: ["ladi.page"],
    contributors: [
      contributors["xoaicc"],
    ],
    deliveryDate: "2024-08-15",
  },
  {
    title: "CDNN official web",
    description: "Official web of Hanoi Foreign Language College.",
    image: "",
    url: "https://cdnn.edu.vn/",
    github: "",
    technologies: ["WordPress"],
    contributors: [
      contributors["xoaicc"],
    ],
    deliveryDate: "2024-08-10",
  },
  {
    title: "TVUni training web",
    description: "Web for online training of Trung Vuong University.",
    image: "",
    url: "",
    github: "",
    technologies: ["WordPress"],
    contributors: [
      contributors["xoaicc"],
    ],
    deliveryDate: "2024-08-08",
  },
  {
    title: "KTT-TVUni official web",
    description: "Official web of Faculty of Economics, Trung Vuong University.",
    image: "",
    url: "https://khoakinhte.tv-uni.edu.vn/",
    github: "",
    technologies: ["WordPress"],
    contributors: [
      contributors["xoaicc"],
    ],
    deliveryDate: "2024-08-01",
  },
  {
    title: "KSDH-TVUni official web",
    description: "Official web of Faculty of Graduation, Trung Vuong University.",
    image: "",
    url: "https://khoasaudaihoc.tvuni.edu.vn/",
    github: "",
    technologies: ["WordPress"],
    contributors: [
      contributors["xoaicc"],
    ],
    deliveryDate: "2024-08-06",
  },
  {
    title: "KL-TVUni official web",
    description: "Official web of Faculty of Law, Trung Vuong University.",
    image: "",
    url: "https://khoaluat.tvuni.edu.vn/",
    github: "",
    technologies: ["WordPress"],
    contributors: [
      contributors["xoaicc"],
    ],
    deliveryDate: "2024-08-05",
  },
  {
    title: "Curtain Store web",
    description: "A web for online curtain store of store owner.",
    image: "",
    url: "",
    github: "",
    technologies: ["WordPress"],
    contributors: [
      contributors["xoaicc"],
    ],
    deliveryDate: "2021-09-27",
  },
  {
    title: "Real Estate web",
    description: "A web for Real Estate of Real Estate company.",
    image: "",
    url: "",
    github: "",
    technologies: ["WordPress"],
    contributors: [
      contributors["xoaicc"],
    ],
    deliveryDate: "2022-10-10",
  },
  {
    title: "XoaiCV 2 web",
    description: "A web for introducing myself, but version 2.",
    image: "",
    url: "https://cv.xoaicc.id.vn",
    github: "https://github.com/xoaicc/xoaicv-2",
    technologies: ["HTML", "CSS", "JS"],
    contributors: [
      contributors["xoaicc"],
    ],
    deliveryDate: "2024-04-10",
  },
  {
    title: "XoaiCV web",
    description: "A web for introducing myself.",
    image: images.XoaiCVWeb,
    url: "https://xoaicc.github.io/xoaicv/",
    github: "https://github.com/xoaicc/xoaicv",
    technologies: ["HTML", "CSS", "JS"],
    contributors: [
      contributors["xoaicc"],
    ],
    deliveryDate: "2023-10-03",
  },
];

export default featuredProjects;