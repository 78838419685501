/**
 * Formats a given date as a relative time string.
 *
 * The function calculates the difference between the current date and the provided date,
 * and returns a string representing how long ago the provided date was in terms of hours,
 * days, or months. If the date is today, it returns "just now" or the number of hours ago.
 * If it's within the past month, it returns the number of days ago. Otherwise, it returns
 * the number of months ago.
 *
 * @param time - The date object to format.
 * @returns A string representing the relative time from the current date.
 */
export default function formatTime(time: Date): string {
  const now = new Date();
  const diffMs = now.getTime() - time.getTime();

  const diffHs = Math.floor(diffMs / (1000 * 60 * 60));
  const diffDs = Math.floor(diffMs / (1000 * 60 * 60 * 24));
  const diffMo = Math.floor(diffMs / (1000 * 60 * 60 * 24 * 30));

  // Check if the date is within today (same day)
  if (diffHs < 24) {
    return diffHs === 0 ? "just now" : `${diffHs} hours ago`;
  }

  // Check if the date is within this month (less than 30 days ago)
  if (diffDs < 30) {
    return `${diffDs} days ago`;
  }

  // Else, return the number of months ago
  return `${diffMo} months ago`;
}