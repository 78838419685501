import { PauseIcon, PlayIcon } from "lucide-react";

import { IconButton } from "components/Buttons";
import Tooltip from "components/Tooltip";
import { useAudio } from "contexts";

/**
 * A button component that can be used to toggle play/pause for the audio player.
 *
 * It also displays the name of the song if it is available.
 *
 * @returns A JSX element representing the button.
 */
export default function PlayMusic(): JSX.Element {
  const { playing, togglePlay, songName } = useAudio();
  const songNameLength = songName.length;

  return (
    <div
      className={`w-12 md:w-10 h-12 md:h-10 ${
        songNameLength > 0 && "group-hover:w-40"
      } flex justify-center items-center rounded-full group-hover:bg-blue-700 group-hover:dark:bg-blue-600 transition-all duration-300`}
    >
      <Tooltip content={playing ? "Stop audio" : "Play audio"}>
        <IconButton
          icon={
            playing ? (
              <PauseIcon className="h-6 md:h-5 w-6 md:w-5" />
            ) : (
              <PlayIcon className="h-6 md:h-5 w-6 md:w-5" />
            )
          }
          onClick={togglePlay}
        />
      </Tooltip>
      <span
        className={`hidden ${
          songNameLength > 0 && "group-hover:inline-block"
        } w-full overflow-x-hidden pe-1 duration-300 text-gray-200 dark:text-gray-700`}
      >
        <span
          className={`inline-block text-nowrap ${
            songNameLength > 11 && "animate-marquee"
          }`}
        >
          {songName}
        </span>
      </span>
    </div>
  );
}
