import React, { lazy, Suspense } from "react";

import StoryBackground from "assets/images/story-bg.png";
import ImageBackground from "components/ImageBackground";
import Breadcrumb from "components/Breadcrumb";
import Section from "components/Section";
import Paragraph from "components/Paragraph";
import Hr from "components/Hr";

import cloudAnimation from "assets/animations/sky-cloud.json";

// Lazy load the Lottie component
const LottiePlayer = lazy(() => import("react-lottie-player"));

export default function StoryPage() {
  const [showAnimation, setShowAnimation] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowAnimation(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <ImageBackground bgColor="yellow" imgSrc={StoryBackground} />
      <Breadcrumb currentRoute={{ name: "My Story", route: "/story" }} />
      {showAnimation && (
        <div className="fixed top-3/4 md:top-1/4 left-0 w-full h-full scale-150 md:scale-100 -z-10">
          <Suspense fallback={<></>}>
            <LottiePlayer
              loop
              animationData={cloudAnimation}
              play
              speed={0.65}
            />
          </Suspense>
        </div>
      )}
      <div className="h-dvh flex justify-center items-center">
        <Paragraph className="mx-auto max-w-3xl text-center">
          I am Vuong &#40;it&#39;s means King in English&#41;. I was born on the
          first day of the second millennium. It was a moment that I later
          realized was really meaningful. My birthplace is Me Linh, also the
          birthplace of two women who led Vietnam&#39;s first insurrection
          following the Common Era. Every year, I go to those two women and
          present them incense.
        </Paragraph>
      </div>
      <div className="space-y-64">
        <Section id="childhood" title="Childhood" delay={3}>
          <Paragraph className="mx-auto max-w-3xl text-center">
            My childhood did not have many stories. Although there were things
            worth forgetting, things that were regrettable, I don&#39;t think
            it&#39;s worth complaining about. I think that because I had a life
            that was better than many others, I wanted to share those good
            things. It was my own responsibility, I don&#39;t want anyone to be
            left behind and I don&#39;t want to enjoy the good things for
            myself.
          </Paragraph>
        </Section>
        <Section id="youth-age" title="Youth Age" delay={3}>
          <Paragraph className="mx-auto max-w-3xl text-center">
            There was a time when I really liked the anime world not only
            because it was beautiful but also because I wanted to escape from
            the present. But why? My family didn&#39;t have any misfortune. That
            was when I thought I was so stupid. Sometimes I even felt why
            everything was still so easy for me. I was afraid that I
            wouldn&#39;t grow up fully if I didn&#39;t really face the worst
            things.
          </Paragraph>
        </Section>
        <Section id="present" title="Present" delay={3}>
          <Paragraph className="mx-auto max-w-3xl text-center">
            Despite some setbacks after finishing my school journey, I never
            felt hopeless for a long time. I have many dreams that I want to
            fulfill. I still had good friends, thank them, at least they do not
            make me feel sorry for my own shortcomings. That is the reason why
            now, I want to move forward beyond anyone&#39;s expectations. Of
            course, I do not live to prove to others, I take decisions for my
            life. I have my own burning desires and ambitions. Now, I am ready
            to move forward.
          </Paragraph>
        </Section>
      </div>
      <div className="h-dvh flex flex-col justify-center items-center">
        <Paragraph className="mx-auto max-w-3xl text-center">
          This is going to be the next chapter of my life. I&apos;m going to
          live a life worth living. And, I am so glad you got to witness this
          moment!
        </Paragraph>
        <Hr />
      </div>
    </>
  );
}
