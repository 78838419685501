import { BookTextIcon, FolderCodeIcon, HomeIcon, RouteIcon, UserPenIcon } from "lucide-react";

import PagesLayout from "layouts/PagesLayout";

import HomePage from "pages/Home";
import StoryPage from "pages/Story";
import CareerPage from "pages/Career";
import ProfilePage from "pages/Profile";
import PortfolioPage from "pages/Portfolio";

import HomeMusic from "assets/audios/sao-open.mp3";
import StoryMusic from "assets/audios/the-nights.mp3";
import CareerMusic from "assets/audios/pirates-of-the-caribbean.mp3";
import ProfileMusic from "assets/audios/trai-tim-giua-bau-troi.mp3";
import PortfolioMusic from "assets/audios/dam-me.mp3";

import { IRoute } from "types/Route";

const routes: IRoute[] = [
  {
    type: "unit",
    name: "Home",
    key: "home",
    icon: HomeIcon,
    route: "/",
    page: HomePage,
    music: {
      name: "Crossing Field - LiSA",
      src: HomeMusic,
    }
  },
  {
    type: "unit",
    name: "My Story",
    key: "story",
    icon: BookTextIcon,
    route: "/story",
    page: StoryPage,
    layout: PagesLayout,
    music: {
      name: "The Nights - AlexD Music Insignt",
      src: StoryMusic,
    }
  },
  {
    type: "unit",
    name: "My Career",
    key: "career",
    icon: RouteIcon,
    route: "/career",
    page: CareerPage,
    layout: PagesLayout,
    music: {
      name: "Pirates of the Caribbean - Hauser",
      src: CareerMusic,
    }
  },
  {
    type: "unit",
    name: "My Profile",
    key: "profile",
    icon: UserPenIcon,
    route: "/profile",
    page: ProfilePage,
    layout: PagesLayout,
    music: {
      name: "Trái tim giữa bầu trời - Trịnh Đình Quang",
      src: ProfileMusic,
    }
  },
  {
    type: "unit",
    name: "My Portfolio",
    key: "portfolio",
    icon: FolderCodeIcon,
    route: "/portfolio",
    page: PortfolioPage,
    layout: PagesLayout,
    music: {
      name: "Đam mê - Double2T",
      src: PortfolioMusic,
    }
  },
];

export default routes;
