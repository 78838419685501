import { useEffect, useState } from "react";
import axios from "axios";

import MixButton from "components/Buttons/MixButton";
import { HorizontalLinkCard } from "components/Cards/LinkCard";
import Section from "components/Section";
import { HorizontalTabs } from "components/Tabs";
import Tab from "components/Tabs/Tab";
import VerticalImageCard from "components/Cards/ImageCard/VerticalImageCard";

import featuredProjects from "./data/featuredProjects";
import { FolderGit2Icon, FolderHeartIcon } from "lucide-react";

export default function ProjectsSection() {
  const [currentTabId, setCurrentTabId] = useState<string>("featured");
  const [repos, setRepos] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [dataFetched, setDataFetched] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [visibleProjects, setVisibleProjects] = useState<number>(6);

  // Fetch data when "Public" tab is opened or when page is updated
  const fetchRepos = async (): Promise<void> => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_GITHUB_API}?per_page=10&page=${page}&sort=updated`
      );
      const newRepos = response.data;

      if (newRepos.length < 10) {
        setHasMore(false);
      }

      if (newRepos.length !== 0) {
        setRepos((prevRepos) => [...prevRepos, ...newRepos]);
      }
    } catch (error: any) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Fetch the first batch of repos when the "Public" tab is opened
  useEffect(() => {
    if (currentTabId === "public" && !dataFetched) {
      (async () => {
        await fetchRepos();
        setDataFetched(true);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTabId]);

  // Fetch more repos when the page is incremented
  useEffect(() => {
    if (page > 1) {
      fetchRepos();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleSeeMore = () => {
    setVisibleProjects((prev) => prev + 3);
  };

  return (
    <Section
      id="projects"
      title="What I'm doing"
      description="Okay, now you will want to see what I really do?"
    >
      <HorizontalTabs center defaultTabId="featured" setState={setCurrentTabId}>
        <Tab label="Featured Projects" tabId="featured" icon={FolderHeartIcon}>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
            {featuredProjects
              .slice(0, visibleProjects)
              .map((project, index) => (
                <VerticalImageCard key={index} projectData={project} />
              ))}
          </div>
          <div className="mt-4 flex justify-center">
            {visibleProjects < featuredProjects.length && (
              <MixButton onClick={handleSeeMore}>See more</MixButton>
            )}
          </div>
        </Tab>
        <Tab label="Public Projects" tabId="public" icon={FolderGit2Icon}>
          <div className="space-y-4">
            {loading
              ? "Loading..."
              : repos?.map((repo, index) => (
                  <HorizontalLinkCard
                    key={index}
                    icon={
                      <img
                        src={repo.owner.avatar_url}
                        className="w-10 h-10 p-1 rounded-full ring-2 ring-gray-300 dark:ring-gray-500"
                        alt="Creator avatar"
                      />
                    }
                    badge={new Date(repo.updated_at)}
                    title={repo.name}
                    description={repo.description}
                    linkUrl={repo.html_url}
                    linkLabel="View on GitHub"
                  />
                ))}

            <div className="flex justify-center">
              {dataFetched && hasMore ? (
                <MixButton onClick={() => setPage(page + 1)} disabled={loading}>
                  {loading ? "Loading..." : "See more"}
                </MixButton>
              ) : (
                dataFetched && "The worlds end here!"
              )}
            </div>
          </div>
        </Tab>
      </HorizontalTabs>
    </Section>
  );
}
