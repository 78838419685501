import { Link } from "react-router-dom";

import { TimeBadge } from "components/Badges";

interface IHorizontalLinkCardProps {
  icon?: React.ReactElement;
  badge?: any;
  title: string;
  description: string;
  buttonUrl?: string;
  buttonLabel?: string;
  linkUrl?: string;
  linkLabel?: string;
  moreClass?: string;
}

/**
 * A horizontal link card component. This component takes in an icon, badge, title, description, buttonUrl, buttonLabel, linkUrl, linkLabel, and moreClass props. It renders a horizontal card with the icon, badge, title, description, button, and link. The badge is rendered as a TimeBadge in the top right corner of the card. The button and link are rendered in a flex box below the description. The button is rendered as a react-router-dom Link component and the link is rendered as a normal anchor tag. The moreClass prop is applied to the outermost div element of the component.
 * @param icon - The icon to be rendered in the card.
 * @param badge - The date object to be formatted and displayed in the badge.
 * @param title - The title of the card.
 * @param description - The description of the card.
 * @param buttonUrl - The url of the button. If not provided, the button will not be rendered.
 * @param buttonLabel - The label of the button. If not provided, the button will not be rendered.
 * @param linkUrl - The url of the link. If not provided, the link will not be rendered.
 * @param linkLabel - The label of the link. If not provided, the link will not be rendered.
 * @param moreClass - Additional classes to be applied to the outermost div element of the component.
 * @returns A JSX element representing the horizontal link card.
 */
export default function HorizontalLinkCard({
  icon,
  badge,
  title,
  description,
  buttonUrl,
  buttonLabel,
  linkUrl,
  linkLabel,
  moreClass,
}: IHorizontalLinkCardProps): JSX.Element {
  return (
    <div
      className={`relative p-6 flex gap-3 i-glassmorphism rounded-lg i-border ${moreClass}`}
    >
      {icon}
      <div>
        <TimeBadge time={badge} moreClass="absolute top-6 right-6" />
        <h4 className="mb-3 font-semibold">{title}</h4>
        <p className="mb-3">{description}</p>
        <div className="flex flex-wrap gap-3">
          {buttonUrl && (
            <Link
              type="button"
              to={buttonUrl}
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              {buttonLabel}
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </Link>
          )}
          {linkUrl && (
            <a
              href={linkUrl}
              className="inline-flex font-medium items-center text-blue-600"
            >
              {linkLabel}
              <svg
                className="w-3 h-3 ms-2.5 rtl:rotate-[270deg]"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 18"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778"
                />
              </svg>
            </a>
          )}
        </div>
      </div>
    </div>
  );
}
