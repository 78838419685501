import { CodeIcon, LucideIcon, Building2Icon, BrainIcon, HandHeartIcon, TheaterIcon } from "lucide-react";

interface CareerDream {
  icon: LucideIcon;
  title: string;
  description: string;
}

export const careerDreams: CareerDream[] = [
  {
    icon: CodeIcon,
    title: "Web Developer",
    description:
      "With a passion for the web, I want to develop and contribute to the best works.",
  },
  {
    icon: Building2Icon,
    title: "CEO & Founder",
    description:
      "I want to conquer and explore with my soulmates, that motivates me every day.",
  },
  {
    icon: BrainIcon,
    title: "The Thinker",
    description:
      "Psychology, philosophy, and anthropology are subjects that I always want to research.",
  },
  {
    icon: HandHeartIcon,
    title: "Social Activist",
    description:
      "I want to go to many places and share from the bottom of my heart. It's a memor",
  },
  {
    icon: TheaterIcon,
    title: "Writer & Director",
    description:
      "My inspiration for cinema is same as the web, that's what I will fight for.",
  },
];
