interface ICardProps {
  children: React.ReactNode;
  className?: string;
}

/**
 * A card component that provides a container with glassmorphism
 * and border styling. It can be used to wrap children components
 * with optional additional classNames.
 *
 * @param children - The content to be displayed inside the card.
 * @param className - Optional additional class names for custom styling.
 * @returns A JSX element representing the card.
 */
export default function Card({ children, className }: ICardProps): JSX.Element {
  return (
    <div className={`i-glassmorphism i-border rounded-lg ${className}`}>
      {children}
    </div>
  );
}

/**
 * A header component for Card that provides a container with padding and
 * flex alignment. It can be used to wrap children components with optional
 * additional classNames.
 *
 * @param children - The content to be displayed inside the header.
 * @param className - Optional additional class names for custom styling.
 * @returns A JSX element representing the header.
 */
Card.Header = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => <div className={`p-4 flex items-center ${className}`}>{children}</div>;

/**
 * A title component for Card that provides a container with
 * inherit text color. It can be used to wrap children components
 * with optional additional classNames.
 *
 * @param children - The content to be displayed inside the title.
 * @param className - Optional additional class names for custom styling.
 * @returns A JSX element representing the title.
 */
Card.Title = ({
  children,
  className,
}: {
  children: string;
  className?: string;
}) => <h3 className={`text-inherit ${className}`}>{children}</h3>;

/**
 * A content component for Card that provides a container with
 * horizontal padding and flex alignment. It can be used to wrap
 * children components with optional additional classNames.
 *
 * @param children - The content to be displayed inside the content.
 * @param className - Optional additional class names for custom styling.
 * @returns A JSX element representing the content.
 */
Card.Content = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => <div className={`p-4 ${className}`}>{children}</div>;

/**
 * A footer component for Card that provides a container with
 * horizontal padding. It can be used to wrap children components
 * with optional additional classNames.
 *
 * @param children - The content to be displayed inside the footer.
 * @param className - Optional additional class names for custom styling.
 * @returns A JSX element representing the footer.
 */
Card.Footer = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => <div className={`p-4 ${className}`}>{children}</div>;

export { Card };
