import { Children, cloneElement, isValidElement, useState } from "react";
import { ChevronDownIcon } from "lucide-react";

interface IAccordionItemProps {
  title: string;
  defaultOpen?: boolean;
  children: React.ReactNode;
  isOpen?: boolean;
  onToggle?: () => void;
}

/**
 * An item of an accordion component.
 *
 * The accordion item can be used to wrap the content that will be toggled.
 *
 * @param title - The title of the accordion item.
 * @param defaultOpen - Whether the accordion item should be open by default.
 * @param children - The content of the accordion item.
 * @param isOpen - Whether the accordion item is open.
 * @param onToggle - The function called when the accordion item is toggled.
 */
function AccordionItem({
  title,
  defaultOpen = false,
  children,
  isOpen = false,
  onToggle,
}: IAccordionItemProps): JSX.Element {
  return (
    <div className="border-t first:border-t-0 border-gray-300 dark:border-gray-600">
      <button
        type="button"
        className={`flex items-center justify-between w-full p-4 rtl:text-right focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:text-gray-400 dark:hover:bg-gray-800 gap-3 ${
          isOpen ? "bg-white dark:bg-gray-900" : "hover:bg-gray-100"
        }`}
        onClick={onToggle}
        aria-expanded={isOpen}
        aria-controls={`accordion-item-body-${title}`}
      >
        <h4>{title}</h4>
        <ChevronDownIcon
          className={`w-4 h-4 shrink-0 transition-transform duration-300 ${
            isOpen ? "rotate-180" : ""
          }`}
        />
      </button>
      <div
        id={`accordion-item-body-${title}`}
        className={`transition-all duration-300 ${isOpen ? "block" : "hidden"}`}
        aria-labelledby={`accordion-item-heading-${title}`}
      >
        <div className="p-5 border-t border-gray-300 dark:border-gray-600 dark:text-gray-400">
          {children}
        </div>
      </div>
    </div>
  );
}

interface IAccordionProps {
  children: React.ReactNode;
}

/**
 * An accordion component.
 *
 * The accordion component can be used to toggle the visibility of content.
 * Only one item can be open at a time.
 *
 * @example
 * <Accordion>
 *   <Accordion.Item title="Item 1">Content 1</Accordion.Item>
 *   <Accordion.Item title="Item 2">Content 2</Accordion.Item>
 * </Accordion>
 *
 * @param children - The content of the accordion. Must be an array of AccordionItem components.
 */
export default function Accordion({ children }: IAccordionProps): JSX.Element {
  const defaultOpenItem = Children.toArray(children).find(
    (child) =>
      isValidElement<IAccordionItemProps>(child) && child.props.defaultOpen
  ) as React.ReactElement | undefined;

  const [openItem, setOpenItem] = useState<string | null>(
    defaultOpenItem?.props.title || null
  );

  return (
    <div className="i-glassmorphism i-border rounded-lg overflow-hidden">
      {Children.map(children, (child) => {
        if (isValidElement<IAccordionItemProps>(child)) {
          return cloneElement(child, {
            isOpen: child.props.title === openItem,
            onToggle: () =>
              setOpenItem(
                child.props.title === openItem ? null : child.props.title
              ),
          });
        }
        return child;
      })}
    </div>
  );
}

Accordion.Item = AccordionItem;
