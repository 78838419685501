import { SparkleIcon } from "lucide-react";

interface HrProps {
  withIcon?: boolean;
}

/**
 * A horizontal rule component that can be used to separate sections of a page.
 * When `withIcon` is true, the component will render a sparkles icon in the
 * middle of the rule.
 *
 * @param {HrProps} [props] - The props for this component
 * @param {boolean} [props.withIcon] - Whether to render the sparkles icon or not
 * @returns {JSX.Element} - The JSX element representing the horizontal rule
 */
export default function Hr({ withIcon }: HrProps) {
  return withIcon ? (
    <div className="inline-flex items-center justify-center w-full">
      <hr className="w-64 h-1 my-8 i-glassmorphism i-border rounded" />
      <div className="absolute px-4 -translate-x-1/2 bg-gray-300 dark:bg-gray-600 left-1/2">
        <SparkleIcon className="w-6 h-6" />
      </div>
    </div>
  ) : (
    <hr className="w-48 h-1 mx-auto my-4 i-border rounded md:my-10" />
  );
}
