import { HomeIcon } from "lucide-react";

import NormalCard from "components/Cards/NormalCard";
import { MixButton } from "components/Buttons";

export default function NotFound() {
  return (
    <div className="h-dvh flex flex-col items-center justify-center i-background">
      <NormalCard className="w-full max-w-md">
        <NormalCard.Content>
          <div className="flex flex-col items-center justify-center space-y-3">
            <h1>404 Not Found</h1>
            <p className="text-lg">Your page is not found!</p>
          </div>
        </NormalCard.Content>
        <NormalCard.Footer className="text-center">
          <MixButton to="/">
            <HomeIcon className="w-4 h-4" />
            Go Home
          </MixButton>
        </NormalCard.Footer>
      </NormalCard>
    </div>
  );
}
