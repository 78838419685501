/**
 * Truncates a given text to a maximum number of words, and appends an
 * ellipsis at the end to indicate that the text has been truncated.
 *
 * @param {string} text The text to be truncated.
 * @param {number} maxWords The maximum number of words to include in the
 *   truncated text.
 *
 * @return {string} The truncated text.
 */
const truncateText = (text: string, maxWords: number): string => {
  const words = text.split(/\s+/);
  if (words.length <= maxWords) {
    return text;
  }
  const truncated = words.slice(0, maxWords).join(" ");
  return truncated + "...";
};

export default truncateText;
