import { useEffect, useRef, useState } from "react";
import { FolderCodeIcon, HomeIcon, RouteIcon, UserPenIcon } from "lucide-react";

import storyVideoSrc from "assets/videos/home-bg-story.mp4";
import storyPosterSrc from "assets/images/posters/home-bg-story.png";
import careerVideoSrc from "assets/videos/home-bg-career.mp4";
import careerPosterSrc from "assets/images/posters/home-bg-career.png";
import profileVideoSrc from "assets/videos/home-bg-profile.mp4";
import profilePosterSrc from "assets/images/posters/home-bg-profile.png";
import portfolioVideoSrc from "assets/videos/home-bg-portfolio.mp4";
import portfolioPosterSrc from "assets/images/posters/home-bg-portfolio.png";

import VideoSlice from "./components/VideoSlice";

export default function HomePage(): JSX.Element {
  const [hoveredId, setHoveredId] = useState("");
  const isAnyHovered = hoveredId !== "";

  const handleHover = (id: string) => {
    setHoveredId(id);
  };

  const containerRef = useRef<HTMLDivElement>(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!isMobile) return;

    const container = containerRef.current;
    if (!container) return;

    let touchStartX = 0;
    let touchEndX = 0;

    const handleTouchStart = (e: TouchEvent) => {
      touchStartX = e.touches[0].clientX;
    };

    const handleTouchMove = (e: TouchEvent) => {
      touchEndX = e.touches[0].clientX;
    };

    const handleTouchEnd = () => {
      const touchDiff = touchStartX - touchEndX;
      const threshold = window.innerWidth * 0.2;

      if (Math.abs(touchDiff) < threshold) {
        container.scrollTo({
          left:
            Math.round(container.scrollLeft / window.innerWidth) *
            window.innerWidth,
          behavior: "smooth",
        });
      } else {
        const direction = touchDiff > 0 ? 1 : -1;
        const newScrollPosition =
          Math.round(
            (container.scrollLeft + direction * window.innerWidth) /
              window.innerWidth
          ) * window.innerWidth;
        container.scrollTo({ left: newScrollPosition, behavior: "smooth" });
      }
    };

    container.addEventListener("touchstart", handleTouchStart);
    container.addEventListener("touchmove", handleTouchMove);
    container.addEventListener("touchend", handleTouchEnd);

    return () => {
      container.removeEventListener("touchstart", handleTouchStart);
      container.removeEventListener("touchmove", handleTouchMove);
      container.removeEventListener("touchend", handleTouchEnd);
    };
  }, [isMobile]);

  return (
    <div
      ref={containerRef}
      className="w-full h-full flex overflow-x-auto snap-x snap-mandatory"
    >
      <VideoSlice
        to="/story"
        color="yellow"
        videoSrc={storyVideoSrc}
        titleAfter="Story"
        icon={
          <HomeIcon className="h-full group-hover:text-yellow-400 transition-colors" />
        }
        description={
          <>
            Where you start to dive deep in{" "}
            <span className="group-hover:underline">my life</span> and really
            understand me.
          </>
        }
        onHover={handleHover}
        id="story"
        isHovered={hoveredId === "story"}
        isAnyHovered={isAnyHovered}
        posterSrc={storyPosterSrc}
      />
      <VideoSlice
        to="/career"
        color="blue"
        videoSrc={careerVideoSrc}
        titleAfter="Career"
        icon={
          <RouteIcon className="h-full group-hover:text-blue-400 transition-colors" />
        }
        description={
          <>
            Where you can follow{" "}
            <span className="group-hover:underline">big journey</span> of me
            after university graduation.
          </>
        }
        onHover={handleHover}
        id="career"
        isHovered={hoveredId === "career"}
        isAnyHovered={isAnyHovered}
        posterSrc={careerPosterSrc}
      />
      <VideoSlice
        to="/profile"
        color="green"
        videoSrc={profileVideoSrc}
        titleAfter="Profile"
        icon={
          <UserPenIcon className="h-full group-hover:text-green-400 transition-colors" />
        }
        description={
          <>
            Where you get{" "}
            <span className="group-hover:underline">overview about me</span>,
            view <span className="group-hover:underline">my CV</span> as well as{" "}
            <span className="group-hover:underline">my info</span>.
          </>
        }
        onHover={handleHover}
        id="profile"
        isHovered={hoveredId === "profile"}
        isAnyHovered={isAnyHovered}
        posterSrc={profilePosterSrc}
      />
      <VideoSlice
        to="/portfolio"
        color="red"
        videoSrc={portfolioVideoSrc}
        titleAfter="Portfolio"
        icon={
          <FolderCodeIcon className="h-full group-hover:text-red-400 transition-colors" />
        }
        description={
          <>
            Where you quickly find{" "}
            <span className="group-hover:underline">all projects</span> of me,
            that's good to know{" "}
            <span className="group-hover:underline">my ability</span>.
          </>
        }
        onHover={handleHover}
        id="portfolio"
        isHovered={hoveredId === "portfolio"}
        isAnyHovered={isAnyHovered}
        posterSrc={portfolioPosterSrc}
      />
    </div>
  );
}
