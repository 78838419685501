import PortfolioBackground from "assets/images/portfolio-bg.png";
import { VerticalLinkCard } from "components/Cards/LinkCard";
import Facts from "components/Facts";
import Section from "components/Section";
import ImageBackground from "components/ImageBackground";
import List from "components/List";
import GridGallery from "components/Gallery/GridGallery";

import ProjectsSection from "./ProjectsSection";
import clients from "./data/clients";

import facts from "./data/facts";
import Breadcrumb from "components/Breadcrumb";
import { services } from "./data/services";
import Accordion from "components/Accordion";

export default function PortfolioPage() {
  return (
    <>
      <ImageBackground bgColor="red" imgSrc={PortfolioBackground} />
      <Breadcrumb
        currentRoute={{ name: "My Portfolio", route: "/portfolio" }}
      />
      <div className="mt-20 mb-10 space-y-64">
        <Section
          id="services"
          title="What I serve"
          description="First, let's see what can I do for you."
        >
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-3 gap-y-6">
            {services.map((service, index) => (
              <VerticalLinkCard
                key={index}
                icon={service.icon}
                title={service.title}
                description={service.description}
                buttonLabel={service.buttonLabel}
                buttonUrl={service.buttonUrl}
                linkLabel={service.linkLabel}
                linkUrl={service.linkUrl}
                moreClass="mx-auto"
              />
            ))}
          </div>
        </Section>

        <Section
          id="reasons"
          title="Why me?"
          description='"Ok, why you instead of other guys?". Let me tell you.'
        >
          <Accordion>
            <Accordion.Item
              title="If you want to develop a modern & amazing website"
              defaultOpen
            >
              {/* Row: 1 */}
              <div className="i-twoColumns">
                <div>
                  <h5>High-tech Web:</h5>
                  <List type="ul" withIcon>
                    <li>
                      Constantly update and change new technology:{" "}
                      <kbd>Remix</kbd>
                    </li>
                    <li>
                      Develop in a comprehensive ecosystem: <kbd>Next.js</kbd>{" "}
                      <kbd>Remix</kbd>
                    </li>
                    <li>
                      Adapt to new trends: <kbd>ChatGBT</kbd> <kbd>Web3</kbd>
                    </li>
                  </List>
                </div>
                <div>
                  <h5>Modern Style:</h5>
                  <List type="ul" withIcon>
                    <li>
                      A fresh combination of <kbd>TailwindCSS</kbd> &{" "}
                      <kbd>Flowbite</kbd>
                    </li>
                    <li>
                      Change to modern and new style with <kbd>Joy UI</kbd>
                    </li>
                    <li>
                      Promote the traditional and intelligent values of{" "}
                      <kbd>SCSS</kbd> or <kbd>LESS</kbd>
                    </li>
                    <li>
                      Take your website to the next level with animations,
                      supported by <kbd>GSAP</kbd> <kbd>Anime.js</kbd>
                    </li>
                  </List>
                </div>
              </div>

              {/* Row: 2 */}
              <div className="i-twoColumns">
                <div>
                  <h5>Quality Source Code:</h5>
                  <List type="ul" withIcon>
                    <li>Fully commented on functions or parts of code</li>
                    <li>
                      Highly structured configuration from global variables to
                      each individual variables
                    </li>
                    <li>
                      Prioritize <kbd>TypeScript</kbd> and <kbd>Prettier</kbd>{" "}
                      to ensure fresh code right from the start
                    </li>
                    <li>
                      Smart and clear folder structure. Full instructions in{" "}
                      <kbd>README.md</kbd>
                    </li>
                  </List>
                </div>
                <div>
                  <h5>Full Features:</h5>
                  <List type="ul" withIcon>
                    <li>Light and dark mode as default</li>
                    <li>Multilingual always available</li>
                    <li>Responsive, SEO, preloading always optimized</li>
                    <li>
                      Integrate <kbd>Elasticsearch</kbd>, AI chat,{" "}
                      <kbd>Redis</kbd> and much more
                    </li>
                  </List>
                </div>
              </div>
            </Accordion.Item>

            <Accordion.Item title="If you want to build a normal website in a short time & low budget">
              <p>This is the content of Item 2</p>
            </Accordion.Item>
          </Accordion>
        </Section>

        <Section
          id="facts"
          title="What I done"
          description="Don't forget to take a look at numbers of fact about me."
        >
          <Facts factData={facts} />
        </Section>

        <ProjectsSection />

        <Section
          id="clients"
          title="My Clients"
          description="How about clients I used to work with? Here are some of them."
        >
          <GridGallery
            images={clients}
            moreClass="grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5"
          />
        </Section>
      </div>
    </>
  );
}
