import { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { PowerIcon } from "lucide-react";

import video from "assets/videos/link-start.mp4";
import { MixButton } from "components/Buttons";
import { NormalCard } from "components/Cards";
import { useLinkStarted, useAudio } from "contexts";

export default function LinkStart(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const { setHasAccess } = useLinkStarted();
  const { initializeAudio } = useAudio();
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoDuration, setVideoDuration] = useState(0);

  const from = location.state?.from
    ? `${location.state.from.pathname}${location.state.from.hash || ""}`
    : "/";

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      const handleLoadedMetadata = () => {
        setVideoDuration(videoElement.duration * 1000);
      };

      videoElement.addEventListener("loadedmetadata", handleLoadedMetadata);

      return () => {
        videoElement.removeEventListener(
          "loadedmetadata",
          handleLoadedMetadata
        );
      };
    }
  }, []);

  useEffect(() => {
    if (isPlaying && videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.error("Không thể phát video:", error);
      });
    }
  }, [isPlaying]);

  const handleLinkStarted = () => {
    const originalTitle = document.title;
    setHasAccess(true);
    setIsPlaying(true);
    initializeAudio();

    let dots = 0;
    document.title = "Starting";
    const interval = setInterval(() => {
      dots = (dots + 1) % 4;
      document.title = "Starting" + ".".repeat(dots);
    }, videoDuration / 4);

    setTimeout(() => {
      clearInterval(interval);
      document.title = originalTitle;
      navigate(from, { replace: true });
    }, videoDuration);
  };

  return (
    <div className="h-dvh flex flex-col items-center justify-center i-background">
      <video
        ref={videoRef}
        className="fixed top-0 left-0 w-full h-dvh object-cover z-0"
        src={video}
        playsInline
        style={{ display: isPlaying ? "block" : "none" }}
      />

      {!isPlaying && (
        <NormalCard className="max-w-md">
          <NormalCard.Content>
            <div className="flex flex-col items-center justify-center space-y-3">
              <h1>Access Point</h1>
              <p className="text-lg text-center">
                You can access one time. If you reload, you will lose access.
              </p>
              <MixButton onClick={handleLinkStarted}>
                <PowerIcon className="w-4 h-4" />
                Link Start
              </MixButton>
            </div>
          </NormalCard.Content>
        </NormalCard>
      )}
    </div>
  );
}
