import { motion } from "framer-motion";

import { useAnimateOnScroll } from "hooks/useAnimateOnScroll";

interface IAnimatedTextProps {
  text: string;
  delay?: number;
}

/**
 * A component that animates text by splitting it into words and applying
 * motion effects as it scrolls into view. The animation uses a spring effect with
 * configurable delay and stagger for child elements.
 *
 * @param {Object} props - The component properties.
 * @param {string} props.text - The text to be animated, split into words.
 * @param {number} [props.delay] - Optional delay factor for staggering the children animations.
 *
 * @returns {JSX.Element} The animated text wrapped in a motion div.
 */
const AnimatedText = ({ text, delay }: IAnimatedTextProps): JSX.Element => {
  const [ref, isVisible] = useAnimateOnScroll<HTMLDivElement>();
  const words = text.split(" ");

  const container = {
    hidden: { opacity: 0 },
    visible: (i = 1) => ({
      opacity: 1,
      transition: {
        staggerChildren: 0.12 * (delay || i),
        delayChildren: 0.06 * (delay || i),
      },
    }),
  };

  const child = {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        damping: 12,
        stiffness: 100,
      },
    },
    hidden: {
      opacity: 0,
      y: 20,
      transition: {
        type: "spring",
        damping: 12,
        stiffness: 100,
      },
    },
  };

  return (
    <motion.div
      style={{
        overflow: "hidden",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        fontSize: "2rem",
        fontWeight: "bold",
        textTransform: "capitalize",
      }}
      variants={container}
      initial="hidden"
      animate={isVisible ? "visible" : "hidden"}
      ref={ref}
    >
      {words.map((word, index) => (
        <motion.span
          variants={child}
          style={{ margin: "0 0.3rem 0.3rem 0.3rem" }}
          key={index}
        >
          {word}
        </motion.span>
      ))}
    </motion.div>
  );
};

export default AnimatedText;
