import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * Scrolls to the element with the ID that matches the URL hash.
 * The scrolling is smooth and happens on every URL change.
 *
 * @returns null
 */
export function ScrollToHash(): null {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.substring(1);
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return null;
}
