import { useAnimateOnScroll } from "hooks/useAnimateOnScroll";

interface Data {
  title: string;
  where: string;
  current: boolean;
  startDate: string;
  endDate: string;
  description?: string;
}

const PathItem = ({ item }: { item: Data }) => {
  const [ref, isVisible] = useAnimateOnScroll<HTMLLIElement>();

  return (
    <li className="mb-10 ml-6" ref={ref}>
      <span
        className={`absolute flex items-center justify-center w-8 h-8 rounded-full -left-4 ring-4 ring-gray-200 ${
          item.current ? "bg-blue-500" : "bg-gray-400"
        } ${isVisible ? "animate-bounceIn" : "opacity-0"}`}
      >
        <svg
          aria-hidden="true"
          className={`w-5 h-5 text-white ${
            item.current ? "text-white" : "text-gray-200"
          }`}
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-11.293a1 1 0 00-1.414 0L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4a1 1 0 000-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </span>
      <h3 className="flex items-center mb-1 text-lg font-semibold text-gray-900">
        {item.title} in {item.where}
        {item.current && (
          <span className="ml-2 bg-blue-100 text-blue-800 text-sm font-medium px-3 py-1 rounded-full">
            Current
          </span>
        )}
      </h3>
      <time className="block mb-2 text-sm font-normal leading-none text-gray-400">
        {item.startDate} - {item.endDate}
      </time>
      {item.description && (
        <p className="text-base font-normal text-gray-500">
          {item.description}
        </p>
      )}
    </li>
  );
};

export default function Path({ data }: { data: Data[] }) {
  return (
    <ol className="relative border-l border-gray-200">
      {data.map((item, index) => (
        <PathItem key={index} item={item} />
      ))}
    </ol>
  );
}
