interface IImageBackgroundProps {
  bgColor: "blue" | "green" | "red" | "yellow";
  imgSrc: string;
}

/**
 * A component to render a background image with a colored overlay.
 *
 * @param {IImageBackgroundProps} props - The props for this component
 * @param {string} props.bgColor - The color of the background overlay. Can be one of "blue", "green", "red", or "yellow"
 * @param {string} props.imgSrc - The url of the background image
 * @returns {JSX.Element} - The JSX element representing the background image with a colored overlay
 */
export default function ImageBackground({
  bgColor,
  imgSrc,
}: IImageBackgroundProps) {
  let bgColorClass = "";

  switch (bgColor) {
    case "blue":
      bgColorClass = "before:bg-blue-200/20 dark:before:bg-blue-700/20";
      break;
    case "green":
      bgColorClass = "before:bg-green-200/20 dark:before:bg-green-700/20";
      break;
    case "red":
      bgColorClass = "before:bg-red-200/20 dark:before:bg-red-700/20";
      break;
    case "yellow":
      bgColorClass = "before:bg-yellow-200/20 dark:before:bg-yellow-700/20";
      break;
    default:
      bgColorClass = "";
  }

  return (
    <div
      className={`fixed inset-0 -z-10 w-screen h-screen before:absolute before:inset-0 ${bgColorClass}`}
    >
      <img
        src={imgSrc}
        alt="background"
        className="w-full h-full object-cover opacity-30"
      />
    </div>
  );
}
