interface ISectionProps {
  children: React.ReactNode;
}

export default function Section({ children }: ISectionProps) {
  return (
    <section className="shadow-lg rounded-lg p-6 i-glassmorphism i-border">
      {children}
    </section>
  );
}
