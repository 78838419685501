import { FolderClockIcon } from "lucide-react";

import formatTime from "utils/formatTime";

interface ITimeBadgeProps {
  moreClass?: string;
  time: Date;
}

/**
 * A badge component that displays a formatted time.
 *
 * This component renders a badge with a clock icon and a formatted time string.
 * It applies various styles for both light and dark themes and allows for additional
 * custom styling via the `moreClass` prop.
 *
 * @param moreClass - Optional additional class names to apply to the badge
 * @param time - The date object to be formatted and displayed
 * @returns A JSX element representing a time badge
 */
export default function TimeBadge({
  moreClass,
  time,
}: ITimeBadgeProps): JSX.Element {
  return (
    <span
      className={`bg-blue-100 text-blue-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded-lg dark:bg-gray-700 dark:text-blue-400 border border-blue-400 dark:border-blue-500 ${moreClass}`}
    >
      <FolderClockIcon className="w-3 h-3 me-1" />
      {formatTime(time)}
    </span>
  );
}
