import {
  ChevronsLeftRightEllipsisIcon,
  LayoutTemplateIcon,
  LucideIcon,
  PanelsTopLeftIcon,
} from "lucide-react";

interface Service {
  icon: LucideIcon;
  title: string;
  description: string;
  buttonLabel?: string;
  buttonUrl?: string;
  linkUrl?: string;
  linkLabel?: string;
}

export const services: Service[] = [
  {
    icon: ChevronsLeftRightEllipsisIcon,
    title: "Web Development",
    description:
      "I specialize in full-stack web applications with new technologies, modern design, and multi-purposes.",
    buttonLabel: "See my projects",
    buttonUrl: "#projects",
  },
  {
    icon: PanelsTopLeftIcon,
    title: "Web Building",
    description:
      "I provide the A to Z web building combo in WordPress, helps you grow fast to market like a rocket.",
    buttonLabel: "See my projects",
    buttonUrl: "#projects",
  },
  {
    icon: LayoutTemplateIcon,
    title: "Product Design",
    description:
      "I am not only creating beautiful designs, but also creating products that are easy to use and meet user needs.",
    linkLabel: "Contact for work",
    linkUrl: "https://zalo.me/0383398867",
  },
];
