import { useState, useCallback } from "react";

interface ITooltipProps {
  direction?: "top" | "bottom" | "left" | "right";
  content: string;
  moreClass?: string;
  children: React.ReactNode;
}

/**
 * A tooltip component that displays additional information when the user hovers over
 * or focuses on the child element. The tooltip can be positioned in one of four directions:
 * top, bottom, left, or right.
 *
 * @param {ITooltipProps} props - The props for the tooltip component.
 * @param {"top" | "bottom" | "left" | "right"} [props.direction="top"] - The direction in which the tooltip will be displayed relative to the child element.
 * @param {string} props.content - The content to be displayed inside the tooltip.
 * @param {string} [props.moreClass] - Additional class names for styling the tooltip container.
 * @param {React.ReactNode} props.children - The child element that triggers the tooltip on hover or focus.
 *
 * @returns {JSX.Element} - The JSX element representing the tooltip.
 */
export default function Tooltip({
  direction = "top",
  content,
  moreClass,
  children,
}: ITooltipProps): JSX.Element {
  const [isVisible, setIsVisible] = useState(false);

  const tooltipClasses = {
    top: "bottom-full left-1/2 transform -translate-x-1/2 mb-2",
    bottom: "top-full left-1/2 transform -translate-x-1/2 mt-2",
    left: "right-full top-1/2 transform -translate-y-1/2 mr-2",
    right: "left-full top-1/2 transform -translate-y-1/2 ml-2",
  };

  const showTooltip = useCallback(() => setIsVisible(true), []);
  const hideTooltip = useCallback(() => setIsVisible(false), []);

  return (
    <div
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      onFocus={showTooltip}
      onBlur={hideTooltip}
      className={`relative w-fit h-full ${moreClass}`}
    >
      {children}
      {isVisible && (
        <div
          role="tooltip"
          className={`absolute z-10 min-w-20 px-3 py-2 text-sm font-medium i-glassmorphism text-center rounded-lg i-border hidden md:block ${tooltipClasses[direction]}`}
        >
          {content}
        </div>
      )}
    </div>
  );
}
