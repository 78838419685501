import { createElement, useState } from "react";

interface IHorizontalTabsProps {
  center?: boolean;
  defaultTabId?: string | null;
  children: JSX.Element[];
  setState?: (tabId: string) => void;
}

/**
 * A horizontal tabs component. This component takes in a center, defaultTabId,
 * children, and setState props. It renders a tabs container with a tab for each
 * child element. The tab with the matching tabId to the defaultTabId prop will
 * be active. The setState prop is an optional prop that will be called with the
 * active tabId when the active tab changes. The center prop is an optional prop
 * that will center the tabs if true.
 *
 * @param {Object} props - The props for this component
 * @param {boolean} [props.center=false] - Whether to center the tabs
 * @param {string|null} [props.defaultTabId=null] - The tabId of the tab to be
 *   active initially
 * @param {JSX.Element[]} props.children - The children elements of this
 *   component. Each child element should be a Tab component.
 * @param {(tabId: string) => void} [props.setState] - The function to be called
 *   with the active tabId when the active tab changes
 * @returns {JSX.Element} - The JSX element representing the horizontal tabs
 */
export default function HorizontalTabs({
  center,
  defaultTabId,
  children,
  setState,
}: IHorizontalTabsProps): JSX.Element {
  const [activeTab, setActiveTab] = useState<string>(defaultTabId || "");

  return (
    <div>
      {/* Tabs */}
      <ul
        className={`flex flex-wrap ${
          center && "justify-center"
        } -mb-px text-sm font-medium text-center`}
        role="tablist"
      >
        {children.map(({ props: { icon, label, tabId } }) => (
          <li className="me-2" role="presentation" key={tabId}>
            <button
              className={`inline-flex items-center p-4 border-b-2 rounded-t-lg text-lg font-semibold group ${
                activeTab === tabId
                  ? "border-blue-600 text-blue-600"
                  : "border-transparent hover:border-blue-600 hover:text-blue-600 dark:hover:border-blue-300 dark:hover:text-blue-300"
              }`}
              type="button"
              role="tab"
              aria-controls={tabId}
              aria-selected={activeTab === tabId}
              onClick={() => {
                setActiveTab(tabId);
                setState && setState(tabId);
              }}
            >
              {icon && (
                <span className="mr-2 p-1 rounded-full i-border group-hover:scale-105 transition-all duration-300 bg-blue-600 text-white">
                  {createElement(icon, {
                    className: "w-6 h-6",
                  })}
                </span>
              )}
              {label}
            </button>
          </li>
        ))}
      </ul>

      {/* Active Tab Content */}
      <div>{children.find((tab) => tab.props.tabId === activeTab)}</div>
    </div>
  );
}
