interface INormalBadgeProps {
  children: React.ReactElement | string;
  className?: string;
}

/**
 * A small badge component that can be used to display a single
 * word or short phrase. It has a default style of being a small
 * rounded rectangle with a font size of 1rem.
 *
 * @param children React node to render inside the badge
 * @param className Custom class name to apply to the badge
 * @returns A JSX element representing the badge
 */
export default function NormalBadge({
  children,
  className,
}: INormalBadgeProps): JSX.Element {
  return (
    <span className={`text-sm font-medium px-3 py-1 rounded-full ${className}`}>
      {children}
    </span>
  );
}
