import { Link } from "react-router-dom";
import { ChevronRightIcon, GlobeIcon, HomeIcon } from "lucide-react";

interface ISite {
  name: string;
  route: string;
}

const otherSites: ISite[] = [
  {
    name: "XoaiCV 2",
    route: "https://cv.xoaicc.id.vn",
  },
  {
    name: "ColAnime Studio",
    route: "https://www.youtube.com/@colanimestudio2952",
  },
  {
    name: "AllWeKnow VN",
    route: "https://allweknow.vn",
  },
  {
    name: "EmonWeb Agency",
    route: "https://emonweb.biz",
  },
];

interface IRoute {
  name: string;
  route: string;
}

interface IBreadcrumbProps {
  currentRoute: IRoute;
  prevRoutes?: IRoute[];
}

/**
 * A functional component that renders a breadcrumb navigation.
 *
 * It takes three props: currentRoute, prevRoutes, and otherSites.
 *
 * currentRoute is an object with two properties: name and route. It
 * represents the current page's breadcrumb.
 *
 * prevRoutes is an array of objects with two properties: name and route. Each
 * object represents a previous page in the breadcrumb path.
 *
 * otherSites is an array of objects with two properties: name and route. Each
 * object represents an external website that can be accessed from the
 * breadcrumb.
 *
 * The component renders a breadcrumb navigation with links to the previous
 * pages and the current page. It also renders a list of external websites at the
 * end of the breadcrumb.
 *
 * @param {IBreadcrumbProps} props - The props for this component.
 * @returns {JSX.Element} A JSX element representing the breadcrumb navigation.
 */
export default function Breadcrumb({
  currentRoute,
  prevRoutes,
}: IBreadcrumbProps): JSX.Element {
  return (
    <nav
      className="flex flex-col md:flex-row justify-between px-5 py-3 i-glassmorphism text-gray-700 rounded-lg i-border space-y-2 md:space-y-0"
      aria-label="Breadcrumb"
    >
      <ul className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
        <li className="inline-flex items-center">
          <Link
            to="/"
            className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
          >
            <HomeIcon className="w-3 h-3" />
          </Link>
        </li>
        {prevRoutes?.map((route, index) => (
          <li key={index}>
            <div className="flex items-center">
              <ChevronRightIcon className="rtl:rotate-180 w-3 h-3 mx-1 text-gray-500" />
              <Link
                to={route.route}
                className="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white"
              >
                {route.name}
              </Link>
            </div>
          </li>
        ))}
        <li aria-current="page">
          <div className="flex items-center">
            <ChevronRightIcon className="rtl:rotate-180 w-3 h-3 mx-1 text-gray-400" />
            <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-500">
              {currentRoute.name}
            </span>
          </div>
        </li>
      </ul>
      <ul className="inline-flex flex-wrap items-center space-x-2 md:space-x-4 rtl:space-x-reverse">
        {otherSites.map((site, index) => (
          <li key={index} className="flex items-center space-x-2 md:space-x-4">
            {index > 0 && <GlobeIcon className="w-3 h-3 text-gray-400" />}
            <a
              href={site.route}
              className="text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-blue-600"
            >
              {site.name}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
}
