import { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { useLinkStarted } from "contexts";

import Modal from "components/Modal";
import NavBar from "components/Navbar";
import { ScrollToHash } from "components/ScrollToHash";

export default function RootLayout() {
  const location = useLocation();
  const { hasAccess } = useLinkStarted();

  useEffect(() => {
    if (!location.hash) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  if (!hasAccess) {
    return <Navigate to="/link-start" state={{ from: location }} replace />;
  }

  if (hasAccess) {
    return (
      <main className="w-dvw min-h-dvh overflow-hidden">
        <Modal />
        <ScrollToHash />
        <Outlet />
        <NavBar />
      </main>
    );
  }

  return null;
}
