import { createContext, useContext, useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import routes from "routes";

interface IGlobalContext {
  modal: {
    isVisible: boolean;
    modalData: IProject | null;
    setModalData: (data: IProject) => void;
    setIsVisible: (isVisible: boolean) => void;
  };
  linkStarted: {
    hasAccess: boolean;
    setHasAccess: (hasAccess: boolean) => void;
  };
  audio: {
    playing: boolean;
    songName: string;
    togglePlay: () => void;
    initializeAudio: () => void;
  };
}

const GlobalContext = createContext<IGlobalContext>({
  modal: {
    isVisible: false,
    setIsVisible: () => {},
    modalData: null,
    setModalData: () => {},
  },
  linkStarted: {
    hasAccess: false,
    setHasAccess: () => {},
  },
  audio: {
    playing: false,
    songName: "",
    togglePlay: () => {},
    initializeAudio: () => {},
  },
});

/**
 * A provider component that wraps the root of the application to provide global
 * state through the GlobalContext.
 *
 * The state includes the following:
 * - modal: State for the modal dialog.
 * - linkStarted: State for whether the user has started the link.
 * - audio: State for the audio player.
 *
 * The component also takes care of initializing the audio player and updating
 * the state when the route changes.
 *
 * @param {React.ReactNode} children - The children of the component.
 * @returns {JSX.Element} A JSX element representing the GlobalProvider component.
 */
export const GlobalProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [modalData, setModalData] = useState<IProject | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [hasAccess, setHasAccess] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [songName, setSongName] = useState("");
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const location = useLocation();
  const [isInitialized, setIsInitialized] = useState(false);

  const findSongName = () => {
    setSongName(
      routes.find((route) => route.route === location.pathname)?.music?.name ||
        ""
    );
  };

  /**
   * Loads the audio for the current route, if it has one.
   * @param {boolean} [autoPlay=false] - Whether to play the audio automatically.
   */
  const loadAudio = (autoPlay = false) => {
    // Find the route matching the current path
    const currentRoute = routes.find(
      (route) => route.route === location.pathname
    );

    if (currentRoute?.music) {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }

      const newAudio = new Audio(currentRoute.music.src);
      newAudio.loop = true;

      newAudio.addEventListener("play", playAudio);
      newAudio.addEventListener("pause", stopAudio);

      audioRef.current = newAudio;

      if (autoPlay) {
        newAudio.play();
        setPlaying(true);
      }
    } else {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        audioRef.current = null;
        setPlaying(false);
      }
    }
  };

  const playAudio = () => setPlaying(true);
  const stopAudio = () => setPlaying(false);

  const togglePlay = () => {
    if (audioRef.current) {
      if (playing) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setPlaying(!playing);
    }
  };

  const initializeAudio = () => {
    loadAudio(true);
    setIsInitialized(true);
  };

  useEffect(() => {
    isInitialized ? loadAudio(true) : loadAudio(false);
    findSongName();

    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.removeEventListener("play", playAudio);
        audioRef.current.removeEventListener("pause", stopAudio);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <GlobalContext.Provider
      value={{
        modal: { isVisible, modalData, setModalData, setIsVisible },
        linkStarted: { hasAccess, setHasAccess },
        audio: { playing, songName, togglePlay, initializeAudio },
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

// Hook để sử dụng context
const useModal = () => {
  return useContext(GlobalContext).modal;
};

const useLinkStarted = () => {
  return useContext(GlobalContext).linkStarted;
};

const useAudio = () => {
  return useContext(GlobalContext).audio;
};

export { useModal, useLinkStarted, useAudio };
