import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  ArrowUpToLineIcon,
  BookTextIcon,
  FolderCodeIcon,
  HomeIcon,
  RouteIcon,
  UserPenIcon,
} from "lucide-react";

import Tooltip from "components/Tooltip";
import { IconButton } from "components/Buttons";

import PlayMusic from "./PlayMusic";

/**
 * A bottom navigation bar with links to all pages and a play music button.
 *
 * The navigation bar is fixed at the bottom of the screen and centered.
 * It is hidden when the user is at the top of the page.
 *
 * The links are represented by icons and are wrapped in a Tooltip component.
 * The tooltips are hidden by default and only appear when the user hovers over the icon.
 *
 * The "To Top" button is only visible when the user is not at the top of the page.
 * It scrolls the user to the top of the page when clicked.
 */
export default function NavBar(): JSX.Element {
  const location = useLocation();
  const [isTop, setIsTop] = useState<boolean>(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setIsTop(true);
      } else {
        setIsTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      className="group fixed bottom-5 left-1/2 -translate-x-1/2 z-40 min-w-[56px] md:min-w-[48px] h-[58px] md:h-[50px] p-1 i-glassmorphism rounded-full flex gap-1 i-border shadow-lg transition-all duration-300 
                    max-sm:max-w-[90%] max-sm:overflow-y-hidden max-sm:overflow-x-auto"
    >
      <Tooltip
        content="Home"
        moreClass="hidden group-hover:inline-block duration-300"
      >
        <IconButton
          icon={<HomeIcon className="h-6 md:h-5 w-6 md:w-5" />}
          disabled={location.pathname === "/"}
          to="/"
        />
      </Tooltip>
      <Tooltip
        content="My Story"
        moreClass="hidden group-hover:inline-block duration-300"
      >
        <IconButton
          icon={<BookTextIcon className="h-6 md:h-5 w-6 md:w-5" />}
          disabled={location.pathname === "/story"}
          to="/story"
        />
      </Tooltip>
      <Tooltip
        content="My Career"
        moreClass="hidden group-hover:inline-block duration-300"
      >
        <IconButton
          icon={<RouteIcon className="h-6 md:h-5 w-6 md:w-5" />}
          disabled={location.pathname === "/career"}
          to="/career"
        />
      </Tooltip>
      <PlayMusic />
      <Tooltip
        content="My Profile"
        moreClass="hidden group-hover:inline-block duration-300"
      >
        <IconButton
          icon={<UserPenIcon className="h-6 md:h-5 w-6 md:w-5" />}
          disabled={location.pathname === "/profile"}
          to="/profile"
        />
      </Tooltip>
      <Tooltip
        content="My Portfolio"
        moreClass="hidden group-hover:inline-block duration-300"
      >
        <IconButton
          icon={<FolderCodeIcon className="h-6 md:h-5 w-6 md:w-5" />}
          disabled={location.pathname === "/portfolio"}
          to="/portfolio"
        />
      </Tooltip>
      <Tooltip
        content="To Top"
        moreClass="hidden group-hover:inline-block duration-300"
      >
        <IconButton
          icon={<ArrowUpToLineIcon className="h-6 md:h-5 w-6 md:w-5" />}
          disabled={isTop}
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        />
      </Tooltip>
    </nav>
  );
}
