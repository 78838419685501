import { useEffect, useRef } from "react";
import {
  FolderGitIcon,
  LayersIcon,
  LinkIcon,
  PackageCheckIcon,
} from "lucide-react";

import { useModal } from "contexts";
import formatTime from "utils/formatTime";
import shortUrl from "utils/shortUrl";

/**
 * A modal component that can be used to render a modal dialog with a title,
 * description, client type, public URL, technologies, project delivery date, and
 * contributors. The modal is displayed when the `isVisible` state is set to true.
 *
 * @returns A JSX element representing the modal dialog.
 */

export default function Modal(): JSX.Element | null {
  const { modalData, isVisible, setIsVisible } = useModal();
  const modalRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isVisible && modalData) {
    return (
      <div
        className={`fixed z-50 h-dvh w-dvw bg-black/50 flex items-center justify-center`}
      >
        <div
          className="w-full md:w-1/2 h-full md:min-h-80 md:max-h-[90%] md:h-fit p-3 flex text-sm text-gray-500 transition-opacity duration-300 i-glassmorphism rounded-lg i-border shadow-lg dark:text-gray-400 dark:bg-gray-800"
          ref={modalRef}
        >
          <div className="me-3 shrink-0">
            <a
              href={modalData.url || "/"}
              className="block p-2 bg-gray-100 rounded-lg dark:bg-gray-700"
            >
              <img
                className="w-8 h-8 rounded-full"
                src={modalData.icon || "/logo192.png"}
                alt={modalData.title || "Project Logo"}
              />
            </a>
          </div>
          <div className="flex-grow">
            <h4 className="mb-1">{modalData.title}</h4>
            <p className="mb-3">{modalData.clientType || "Secred Client"}</p>
            <p className="mb-4 text-sm">{modalData.description}</p>
            <ul className="text-sm">
              <li className="flex items-center mb-2">
                <span className="me-2 font-semibold">
                  <LinkIcon className="w-4 h-4" />
                </span>
                <b className="me-2">Public URL:</b>
                {modalData.url ? (
                  <a
                    href={modalData.url}
                    className="text-blue-600 dark:text-blue-500 hover:underline"
                  >
                    {shortUrl(modalData.url, 40)}
                  </a>
                ) : (
                  <p>Link not available</p>
                )}
              </li>
              <li className="flex items-center mb-2">
                <span className="me-2 font-semibold">
                  <FolderGitIcon className="w-4 h-4" />
                </span>
                <b className="me-2">Github repo:</b>
                {modalData.github ? (
                  <a
                    href={modalData.github}
                    className="text-blue-600 dark:text-blue-500 hover:underline"
                  >
                    {shortUrl(modalData.github, 40)}
                  </a>
                ) : (
                  <p>Github not available</p>
                )}
              </li>
              <li className="flex items-center mb-2">
                <span className="me-2 font-semibold">
                  <LayersIcon className="w-4 h-4" />
                </span>
                <b className="me-2">Technologies:</b>
                {modalData.technologies ? (
                  <span className="space-x-1 flex flex-wrap">
                    {modalData.technologies.map((tech, index) => (
                      <kbd key={index}>{tech}</kbd>
                    ))}
                  </span>
                ) : (
                  <p>Technologies not available</p>
                )}
              </li>
              <li className="flex items-center mb-2">
                <span className="me-2 font-semibold">
                  <PackageCheckIcon className="w-4 h-4" />
                </span>
                <b className="me-2">Project Delivery:</b>
                {modalData.deliveryDate ? (
                  formatTime(
                    typeof modalData.deliveryDate === "string"
                      ? new Date(modalData.deliveryDate)
                      : modalData.deliveryDate
                  )
                ) : (
                  <p>Delivery date not available</p>
                )}
              </li>
            </ul>
            <div className="flex mb-3 -space-x-3 rtl:space-x-reverse">
              {modalData.contributors.slice(0, 3).map((contributor, index) => (
                <a href={contributor.url || "/"} key={index}>
                  <img
                    className="flex items-center justify-center w-8 h-8 text-xs font-medium text-white bg-gray-400 border-2 border-white rounded-full hover:bg-gray-500 dark:border-gray-800"
                    src={
                      contributor.image ||
                      `${
                        process.env.REACT_APP_AVATAR_API
                      }${contributor.name.replace(" ", "+")}`
                    }
                    alt={contributor.name}
                  />
                </a>
              ))}
              {modalData.contributors.length > 3 && (
                <span className="flex items-center justify-center w-8 h-8 text-xs font-medium text-white bg-gray-400 border-2 border-white rounded-full hover:bg-gray-500 dark:border-gray-800">
                  +{modalData.contributors.length - 3}
                </span>
              )}
            </div>
          </div>
          <button
            type="button"
            onClick={() => setIsVisible(false)}
            className="shrink-0 w-8 h-8 flex justify-center items-center text-gray-600 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm dark:hover:bg-gray-600 dark:hover:text-white"
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
