import { Link } from "react-router-dom";

/**
 * A footer component that displays copyright information and
 * navigation links such as Privacy Policy and Contact.
 *
 * The footer is styled with a glassmorphism effect and rounded
 * borders. It is responsive and adjusts layout for different screen
 * sizes.
 *
 * @returns A JSX element representing the footer.
 */
export default function Footer(): JSX.Element {
  return (
    <footer className="mt-10 p-4 md:flex md:items-center md:justify-between i-glassmorphism rounded-lg i-border">
      <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
        © 2024{" "}
        <Link to="/" className="hover:underline">
          XoaiCC
        </Link>
        . All Rights Reserved.
      </span>
      <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
        <li>
          <Link to="#" className="hover:underline me-4 md:me-6">
            Privacy Policy
          </Link>
        </li>
        <li>
          <Link to="#" className="hover:underline">
            Contact
          </Link>
        </li>
      </ul>
    </footer>
  );
}
