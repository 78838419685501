/**
 * Shorten a URL by removing the protocol and www. subdomain, and
 * then truncating it to the specified maximum length. If the URL
 * is longer than the maximum length, it will be truncated to the
 * maximum length minus 3, and then have "..." appended to it.
 *
 * @param {string} url - The URL to shorten.
 * @param {number} maxLength - The maximum length of the shortened URL.
 * @returns {string} - The shortened URL.
 */
const shortUrl = (url: string, maxLength: number): string => {
  let shortened = url
    .replace(/^(https?:\/\/)?(www\.)?/, '')
    .replace(/\/$/, '');

  if (shortened.length > maxLength) {
    shortened = shortened.substring(0, maxLength - 3) + "...";
  }

  return shortened;
};

export default shortUrl;
