interface ParagraphProps {
  children: React.ReactNode;
  className?: string;
}

/**
 * A component that renders a paragraph of text with a colorful gradient
 * background, using the Rochester font. The text is also decorated with
 * an underline.
 *
 * @param {ParagraphProps} props - The props for the component
 * @returns {JSX.Element} - The JSX element representing the component
 *
 * @example
 * <Paragraph>
 *   This is an example paragraph of text.
 * </Paragraph>
 */
export default function Paragraph({
  children,
  className = "",
}: ParagraphProps): JSX.Element {
  return (
    <p
      className={`
        font-rochester text-4xl bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 bg-clip-text text-transparent decoration-pink-500 transition-all duration-500 ease-in-out transform hover:scale-105 hover:text-pink-500 ${className}
      `}
    >
      {children}
    </p>
  );
}
