const skills: string[] = [
  "HTML",
  "CSS",
  "SCSS",
  "Tailwind CSS",
  "JavaScript",
  "TypeScript",
  "React",
  "Next.js",
  "Node.js",
  "Express",
  "SQL",
  "MongoDB",
  "MySQL",
  "PostgreSQL",
  "Docker",
];

export default skills;
