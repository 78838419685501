const tools: string[] = [
  "Office",
  "Adobe",
  "Canva",
  "Figma",
  "Git",
  "Postman",
  "DBeaver",
  "Notion",
  "ChatGPT",
  "VSCode",
  "GitHub",
  "Ubuntu",
  "Docker",
  "Docker Desktop",
  "Termius",
];

export default tools;
