interface Tags {
  [key: string]: string;
}

export const tags: Tags = {
  "#SocialNetwork": "bg-blue-500",
  "#ProjectHunting": "bg-sky-500",
  "#Freelancing": "bg-yellow-500",
  "#PersonalBrand": "bg-green-500",
  "#DigitalIdentity": "bg-blue-500",
  "#Showcase": "bg-red-500",
  "#KnowledgeSharing": "bg-purple-500",
  "#Contributing": "bg-indigo-500",
  "#LevelTesting": "bg-orange-500",
  "#Lessons": "bg-pink-500",
};

interface ProjectIdea {
  title: string;
  description: string;
  tags: string[];
}

export const projectIdeas: ProjectIdea[] = [
  {
    title: "Freedom Land",
    description:
      "Freedom Land is an idea of a community platform that loves sharing, connecting, and freelancing. Participants can search for people with the same passion, connect, and work. This is an ideal platform for: freelancers, freelance teams, people who want to find someone to share with,..",
    tags: ["#SocialNetwork", "#ProjectHunting", "#Showcase", "#Freelancing"],
  },
  {
    title: "Show Yourself",
    description:
      "Show Yourself is an idea of a platform that allows users to create their own digital identity and showcase their skills and experiences. It also has a system of testing, evaluating, and certifying skills. Users are even recommended improvements based on a predetermined path or a path that is judged to be suitable for them.",
    tags: ["#PersonalBrand", "#DigitalIdentity", "#Showcase", "#LevelTesting"],
  },
  {
    title: "All We Know",
    description:
      "All We Know is a knowledge sharing platform with a huge repository of knowledge and topics. It allows many contributors like Wikipedia and organizes it in the form of lessons so that everyone can access knowledge for free.",
    tags: ["#KnowledgeSharing", "#Contributing", "#Lessons"],
  },
];
