import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

import ImageBackground from "components/ImageBackground";
import ProfileBackground from "assets/images/profile-bg.png";
import Breadcrumb from "components/Breadcrumb";
import Section from "./components/Section";
import TypingEffect from "./components/TypingEffect";

import workingInfos from "./data/workingInfos";
import roles from "./data/roles";
import links from "./data/links";
import workExperiences from "./data/workingExperiences";
import skills from "./data/skills";
import educationRoad from "./data/educationRoad";
import certificates from "./data/certificates";
import prizes from "./data/prizes";
import avatars from "./data/avatar";
import Path from "./components/Path";
import personalInfos from "./data/personalInfos";
import highlight from "./data/highlight";
import tools from "./data/tools";

export default function ProfilePage() {
  const [currentWorkingIndex, setCurrentWorkingIndex] = useState(0);
  const [currentAvatarIndex, setCurrentAvatarIndex] = useState(0);
  const [showAvatar, setShowAvatar] = useState(true);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  useEffect(() => {
    const timer = setInterval(() => {
      setShowAvatar(false);
      setTimeout(() => {
        setCurrentWorkingIndex(
          (prevIndex) => (prevIndex + 1) % workingInfos.length
        );
        setCurrentAvatarIndex((prevIndex) => (prevIndex + 1) % avatars.length);
        setShowAvatar(true);
      }, 500);
    }, 3000);

    return () => clearInterval(timer);
  }, []);

  return (
    <>
      <ImageBackground bgColor="green" imgSrc={ProfileBackground} />
      <Breadcrumb currentRoute={{ name: "My Profile", route: "/profile" }} />
      <div className="w-full mt-10 space-y-6">
        <Section>
          <div className="flex flex-col md:flex-row items-center space-y-6 md:space-y-0 md:space-x-6">
            <div>
              <div className="relative w-32 h-32">
                <AnimatePresence mode="wait">
                  {showAvatar && (
                    <motion.img
                      key={currentAvatarIndex}
                      src={avatars[currentAvatarIndex].src}
                      alt={avatars[currentAvatarIndex].alt}
                      className="absolute top-0 left-0 rounded-full w-full h-full shadow-lg object-cover"
                      initial={{ opacity: 0, scale: 0.8 }}
                      animate={{ opacity: 1, scale: 1 }}
                      exit={{ opacity: 0, scale: 0.8 }}
                      transition={{
                        duration: 0.5,
                        ease: "easeInOut",
                      }}
                      loading="eager"
                    />
                  )}
                </AnimatePresence>
              </div>
              <p className="mt-2 w-32 text-center">
                {avatars[currentAvatarIndex].alt}
              </p>
            </div>
            <div className="space-y-2 text-center md:text-left">
              <h1 className="text-4xl font-bold text-gray-800">Vuong Do</h1>
              <TypingEffect textList={roles} />
              <div className="flex flex-wrap justify-center md:justify-start gap-2 md:gap-4 mt-2">
                {links.map((link, index) => (
                  <motion.a
                    key={link.name}
                    href={link.url}
                    className="group relative flex items-center justify-center w-10 h-10 rounded-full bg-white shadow-lg transition-all duration-300 ease-in-out hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 hover:z-50"
                    onHoverStart={() => setHoveredIndex(index)}
                    onHoverEnd={() => setHoveredIndex(null)}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.95 }}
                    aria-label={link.name}
                  >
                    {link.icon && (
                      <link.icon className="w-5 h-5 text-gray-600 group-hover:text-blue-500 transition-colors duration-300" />
                    )}
                    {hoveredIndex === index && (
                      <motion.span
                        className="absolute top-12 px-2 py-1 bg-blue-500 text-white text-xs rounded-lg whitespace-nowrap z-50"
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.2 }}
                      >
                        {link.name}
                      </motion.span>
                    )}
                  </motion.a>
                ))}
              </div>
              <div className="h-12 relative overflow-hidden">
                <AnimatePresence>
                  <motion.div
                    key={currentWorkingIndex}
                    initial={{ rotateX: -90, opacity: 0, position: "absolute" }}
                    animate={{ rotateX: 0, opacity: 1 }}
                    exit={{ rotateX: 90, opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className="w-full text-center md:text-left"
                  >
                    {workingInfos[currentWorkingIndex].label}:{" "}
                    <b>{workingInfos[currentWorkingIndex].value}</b>
                  </motion.div>
                </AnimatePresence>
              </div>
            </div>
            <div className="flex-1 flex justify-end gap-4">
              {highlight.map((item, index) => (
                <div
                  key={index}
                  className="relative w-32 h-32 rounded-full shadow-lg"
                >
                  <div className="absolute inset-0 bg-gradient-to-tr from-blue-700 to-blue-200 animate-pulse rounded-full"></div>
                  <div className="absolute inset-1 bg-white rounded-full flex flex-col items-center justify-center">
                    <span className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-700 to-blue-200">
                      {item.value}
                    </span>
                    <span className="text-sm text-center">{item.label}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Section>

        <Section>
          <h2 className="text-2xl mb-4">Personal Information</h2>
          <ul className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {personalInfos.map((info, index) => (
              <li
                key={index}
                className="flex items-center bg-blue-100 text-blue-800 rounded-lg shadow-lg hover:shadow-none p-3 transition-all duration-300 ease-in-out"
              >
                <span className="bg-white p-2 rounded-full mr-3">
                  <info.icon className="w-5 h-5" />
                </span>
                <span>
                  <span className="font-medium">{info.label}:</span>
                  <span className="ml-1">{info.value}</span>
                </span>
              </li>
            ))}
          </ul>
        </Section>

        <Section>
          <h2 className="text-2xl mb-4">About Me</h2>
          <p>
            I am a web lover. I love beautiful, well-designed websites with a
            mix of art and creativity.
          </p>
          <p>
            When I came to the web ? It is in 2019, I started with some websites
            in WordPress platform. That time, I realized that I will go together
            with it. However, I really chose lately the path of true web
            development in 2022. Since then, I want to study more than just web
            development. I really expect to lead an amazing team with a working
            style in my thinking.
          </p>
          <p>
            About working style, I always want to find ways to optimize, clean,
            and reuse source code. When working in a team, I want the whole team
            to be able to develop in a common way, a consistent language from
            the first step to the last step, from the first role to the last
            role.
          </p>
        </Section>

        <Section>
          <div className="i-twoColumns">
            <div>
              <h2 className="text-2xl mb-4">Tech Skills</h2>
              <div className="flex flex-wrap gap-2">
                {skills.map((skill) => (
                  <span className="bg-blue-100 text-blue-800 text-sm font-medium px-3 py-1 rounded-full">
                    {skill}
                  </span>
                ))}
              </div>
            </div>
            <div>
              <h2 className="text-2xl mb-4">Tools & Others</h2>
              <div className="flex flex-wrap gap-2">
                {tools.map((tool) => (
                  <span className="bg-blue-100 text-blue-800 text-sm font-medium px-3 py-1 rounded-full">
                    {tool}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </Section>

        <Section>
          <h2 className="text-2xl mb-4">Work Experience</h2>
          <div className="px-6">
            <Path
              data={workExperiences.map((work) => ({
                ...work,
                where: work.company,
              }))}
            />
          </div>
        </Section>

        <Section>
          <h2 className="text-2xl mb-4">Education Road</h2>
          <div className="px-6">
            <Path
              data={educationRoad.map((education) => ({
                ...education,
                title: education.degree,
                where: education.school,
              }))}
            />
          </div>
        </Section>

        <Section>
          <div className="i-twoColumns">
            <div>
              <h2 className="text-2xl mb-4">Certificates</h2>
              {certificates.map((certificate, index) => (
                <div key={index} className="mb-4">
                  <h3 className="text-lg font-semibold text-gray-900">
                    {certificate.title}
                  </h3>
                  <p className="text-sm text-gray-500">{certificate.date}</p>
                </div>
              ))}
            </div>
            <div>
              <h2 className="text-2xl mb-4">Prizes</h2>
              {prizes.map((prize, index) => (
                <div key={index} className="mb-4">
                  <h3 className="text-lg font-semibold text-gray-900">
                    {prize.title}
                  </h3>
                  <p className="text-sm text-gray-500">{prize.date}</p>
                </div>
              ))}
            </div>
          </div>
        </Section>
      </div>
    </>
  );
}
