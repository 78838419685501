interface WorkExperience {
  title: string;
  company: string;
  current: boolean;
  startDate: string;
  endDate: string;
  description?: string;
}

const workExperiences: WorkExperience[] = [
  {
    title: "Full Stack Developer",
    company: "Freelancer",
    current: true,
    startDate: "Sep 2023",
    endDate: "Present",
    description:
      "Developed multiple full-stack projects using React, Node.js, and TypeScript for high-profile clients. Led a team of 6 engineers in agile environments.",
  },
];

export default workExperiences;
