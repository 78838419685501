interface Certificate {
  title: string;
  date: string;
}

const certificates: Certificate[] = [
  {
    title: "Full Stack Engineer - Codecademy",
    date: "2024",
  },
  {
    title: "English level B2 (CEFR) - Hanoi University",
    date: "2023",
  },
];

export default certificates;
