import formatNumber from "utils/formatNumber";

interface IFactProps {
  factData: IFact[] | [];
}

/**
 * A component that renders a list of facts.
 *
 * @param {IFactProps} props - The props for this component
 * @param {IFact[]} props.factData - An array of facts to be rendered
 * @returns {JSX.Element} A JSX element representing a list of facts
 */
export default function Facts({ factData }: IFactProps): JSX.Element {
  return (
    <dl className="grid max-w-screen-xl grid-cols-2 gap-8 p-4 mx-auto text-gray-900 sm:grid-cols-3 xl:grid-cols-4 dark:text-white sm:p-8">
      {factData.map((fact: IFact, index: number) => (
        <div
          key={index}
          className="p-6 flex flex-col items-center justify-center hover:i-glassmorphism rounded-lg hover:i-border transition-all duration-300 ease-in-out h-40 overflow-hidden"
        >
          <dt className="mb-2 text-3xl font-extrabold">
            {fact.count ? formatNumber(fact.count) : fact.percent + "%"}
          </dt>
          <dd className="text-center text-gray-500 dark:text-gray-400">
            {fact.title}
          </dd>
        </div>
      ))}
    </dl>
  );
}
