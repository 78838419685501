import { Link } from "react-router-dom";

interface IconButtonProps {
  icon: React.ReactElement;
  description?: React.ReactElement;
  disabled?: boolean;
  onClick?: () => void;
  moreClass?: string;
  [key: string]: any;
}

/**
 * A button component that can be used to render an icon with an optional description.
 * The description is only visible to screen readers.
 *
 * @param icon - The icon to be rendered.
 * @param description - The description of the icon, this is only visible to screen readers.
 * @param disabled - Whether the button is disabled.
 * @param onClick - The function that will be called when the button is clicked.
 * @param moreClass - Additional classes that can be added to the button.
 * @param props - Other props that can be passed to the button.
 *
 * @returns A JSX element representing the button.
 */
export default function IconButton({
  icon,
  description,
  disabled = false,
  onClick,
  moreClass,
  ...props
}: IconButtonProps): JSX.Element {
  const T: typeof Link | "button" = props.to ? Link : "button";
  onClick = disabled ? () => {} : onClick;

  return (
    <T
      type="button"
      className={`max-w-fit h-auto text-white ${
        disabled
          ? "bg-gray-500 cursor-not-allowed"
          : "bg-blue-700 dark:bg-blue-600 hover:bg-blue-800 dark:hover:bg-blue-700"
      } focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-full text-sm p-3 md:p-2.5 text-center inline-flex items-center ${moreClass}`}
      onClick={onClick}
      to={props.to}
      {...props}
    >
      {icon}
      <span className="sr-only">{description}</span>
    </T>
  );
}
