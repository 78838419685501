interface EducationRoad {
  school: string;
  degree: string;
  current: boolean;
  startDate: string;
  endDate: string;
}

const educationRoad: EducationRoad[] = [
  {
    school: "Hanoi University",
    degree: "B.Sc. in Information Technology",
    current: false,
    startDate: "2019",
    endDate: "2023",
  },
  {
    school: "Ben Tre High School",
    degree: "High Degree",
    current: false,
    startDate: "2016",
    endDate: "2019",
  },
  {
    school: "Truong Vuong Secondary School",
    degree: "Middle Degree",
    current: false,
    startDate: "2012",
    endDate: "2016",
  },
  {
    school: "Thanh Lam A Primary School",
    degree: "Primary Degree",
    current: false,
    startDate: "2006",
    endDate: "2012",
  },
];

export default educationRoad;
