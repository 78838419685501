import { Route, Routes } from "react-router-dom";

import LinkStart from "pages/LinkStart";
import { IRoute } from "types/Route";

import allRoutes from "./routes";
import NotFound from "./pages/NotFound";
import RootLayout from "./layouts/RootLayout";
import { GlobalProvider } from "contexts";

function App() {
  const getRoutes: any = (routes: IRoute[]) =>
    routes.map((route: IRoute) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            key={route.key}
            path={route.route}
            element={
              route.layout ? (
                <route.layout>
                  <route.page />
                </route.layout>
              ) : (
                <route.page />
              )
            }
          />
        );
      }

      return null;
    });

  return (
    <GlobalProvider>
      <Routes>
        <Route element={<RootLayout />}>{getRoutes(allRoutes)}</Route>
        <Route path="/link-start" element={<LinkStart />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </GlobalProvider>
  );
}

export default App;
