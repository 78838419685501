import { BookHeartIcon, DropletIcon, LucideIcon, CalendarIcon, FlagIcon, MapPinHouseIcon, BookUserIcon, HeartCrackIcon, HeartIcon } from "lucide-react";

interface PersonalInfo {
  icon: LucideIcon;
  label: string;
  value: string;
}

const personalInfos: PersonalInfo[] = [
  {
    icon: BookHeartIcon,
    label: "Gender",
    value: "Male",
  },
  { icon: DropletIcon, label: "Blood type", value: "O+" },
  { icon: CalendarIcon, label: "Date of birth (Sun)", value: "2001 Jan 25" },
  { icon: MapPinHouseIcon, label: "Address", value: "Thanh Lam, Me Linh, Ha Noi" },
  { icon: HeartCrackIcon, label: "Marital status", value: "Single" },
  { icon: FlagIcon, label: "Nationality", value: "Vietnamese" },
  { icon: BookUserIcon, label: "Religion", value: "N/A" },
  { icon: HeartIcon, label: "Hobbies", value: "Web, Movie, Music, Novel, Anime, Karate, Running" },
];

export default personalInfos;
