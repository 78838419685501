import { useEffect, useRef, useState } from "react";

interface AnimateOnScrollOptions {
  threshold?: number;
  rootMargin?: string;
  triggerOnce?: boolean;
}

/**
 * A hook to animate an element on scroll.
 *
 * @param {AnimateOnScrollOptions} [options] - Options to customize the behavior of the hook.
 * @param {number} [options.threshold=0.1] - The intersection ratio threshold
 * @param {string} [options.rootMargin="0px"] - The root margin to check for intersection
 * @param {boolean} [options.triggerOnce=true] - If true, the effect will only be triggered once
 * @returns {[React.MutableRefObject<T | null>, boolean]} - An array containing the ref and the visible state
 */
export function useAnimateOnScroll<T extends HTMLElement | SVGTextElement>({
  threshold = 0.1,
  rootMargin = "0px",
  triggerOnce = true,
}: AnimateOnScrollOptions = {}) {
  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef<T | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          if (triggerOnce && elementRef.current) {
            observer.unobserve(elementRef.current);
          }
        } else if (!triggerOnce) {
          setIsVisible(false);
        }
      },
      { threshold, rootMargin }
    );

    const currentElement = elementRef.current;
    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, [threshold, rootMargin, triggerOnce]);

  return [elementRef, isVisible] as const;
}
