import { Link } from "react-router-dom";

interface IMixButtonProps {
  moreClass?: string;
  children: React.ReactNode;
  [key: string]: any;
}

/**
 * A button component that can be used to render an icon and a text together.
 * The component can be used as a normal button or as a Link from react-router-dom.
 *
 * @param moreClass - Additional classes that can be added to the button.
 * @param children - The icon and text that will be rendered inside the button.
 * @param props - Other props that can be passed to the button.
 *
 * @returns A JSX element representing the button.
 */
export default function MixButton({
  moreClass = "",
  children,
  ...props
}: IMixButtonProps): JSX.Element {
  const T: typeof Link | "button" = props.to ? Link : "button";

  return (
    <T
      type="button"
      to={props.to}
      className={`text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 i-border gap-2 ${moreClass}`}
      {...props}
    >
      {children}
    </T>
  );
}
