import { useRef } from "react";
import { Link } from "react-router-dom";

interface IVideoSliceProps {
  to: string;
  color: "blue" | "green" | "red" | "yellow";
  videoSrc: string;
  titleAfter: string;
  icon: React.ReactElement;
  description: React.ReactElement;
  onHover: (id: string) => void;
  isHovered: boolean;
  isAnyHovered: boolean;
  id: string;
  posterSrc: string;
}

export default function VideoSlice({
  to,
  color,
  videoSrc,
  titleAfter,
  icon,
  description,
  onHover,
  isHovered,
  isAnyHovered,
  id,
  posterSrc,
}: IVideoSliceProps): JSX.Element {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  let bgColor: string = "";
  let textColor: string = "";
  let borderColor: string = "";

  switch (color) {
    case "blue":
      bgColor = "before:bg-blue-300/30";
      textColor = "group-hover:first-letter:text-blue-400";
      borderColor = "border-blue-400";
      break;
    case "green":
      bgColor = "before:bg-green-300/30";
      textColor = "group-hover:first-letter:text-green-400";
      borderColor = "border-green-400";
      break;
    case "red":
      bgColor = "before:bg-red-300/30";
      textColor = "group-hover:first-letter:text-red-400";
      borderColor = "border-red-400";
      break;
    case "yellow":
      bgColor = "before:bg-yellow-300/30";
      textColor = "group-hover:first-letter:text-yellow-400";
      borderColor = "border-yellow-400";
      break;
    default:
      break;
  }

  const handleMouseEnter = () => {
    onHover(id);
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const handleMouseLeave = () => {
    onHover("");
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  };

  const getWidth = () => {
    if (window.innerWidth < 768) return;
    if (!isAnyHovered) return "md:w-1/4";
    if (isHovered) return " md:w-1/3";
    return "md:w-[calc(2/9*100%)]";
  };

  return (
    <Link
      to={to}
      className={`none group relative w-screen ${getWidth()} h-dvh flex-shrink-0 flex transition-all duration-300 before:absolute before:inset-0 before:opacity-50 before:z-10 hover:before:backdrop-blur ${bgColor}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* Video Background */}
      <video
        ref={videoRef}
        className="absolute top-0 left-0 z-0 w-full h-full object-cover"
        muted
        loop
        playsInline
        autoPlay
        preload="metadata"
        poster={posterSrc}
      >
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Content */}
      <div className="relative z-10 w-80 m-auto text-center text-white space-y-4">
        <h2 className="inverse-color">
          My{" "}
          <span
            className={`inline-block ${textColor} first-letter:font-bold first-letter:text-5xl transition-colors`}
          >
            {titleAfter}
          </span>
        </h2>
        <div
          className={`h-10 w-10 p-2 group-hover:border-[1px] ${borderColor} mx-auto rounded-full bg-gray-200 dark:bg-gray-700 group-hover:scale-125 duration-300`}
        >
          {icon}
        </div>
        <p className="i-leading inverse-color">{description}</p>
      </div>
    </Link>
  );
}
