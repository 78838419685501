import {
  BookOpenTextIcon,
  HeartIcon,
  LightbulbIcon,
  LucideIcon,
  ShipIcon,
  SparklesIcon,
} from "lucide-react";

interface CoreValue {
  icon: LucideIcon;
  title: string;
  description: string;
}

export const coreValues: CoreValue[] = [
  {
    icon: HeartIcon,
    title: "Burning Passion",
    description:
      "The Web (WWW) has been, is, and will always be inseparable from me. My life is tied to it.",
  },
  {
    icon: LightbulbIcon,
    title: "Creative Outpouring",
    description:
      "I am always full of ideas. And, I can find them anytime, anywhere, even under my pillow :))",
  },
  {
    icon: SparklesIcon,
    title: "Artist's Soul",
    description:
      "I think I have a strong passion for art. I want to express all of that. You will see my performances soon.",
  },
  {
    icon: BookOpenTextIcon,
    title: "Continuous Learning",
    description:
      "I think I have taken knowledge for granted. Now, I will spend the rest of my life searching for it all.",
  },
  {
    icon: ShipIcon,
    title: "Great Connection",
    description:
      "I always wanted to be able to find great pilots and companions on a big boat together. Where are they?",
  },
];
