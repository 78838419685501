interface Prize {
  title: string;
  date: string;
}

const prizes: Prize[] = [
  {
    title: "Second Prize - Vinh Phuc Province's Excellent IT Student Award",
    date: "2017",
  },
];

export default prizes;