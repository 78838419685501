import Vuong1 from "assets/images/profile/vuong-2023-1.jpeg";
import Vuong2 from "assets/images/profile/vuong-2023-2.jpeg";
import Vuong3 from "assets/images/profile/vuong-2024-1.jpeg";
import Vuong4 from "assets/images/profile/vuong-2024-2.jpg";

interface Avatar {
  src: string;
  alt: string;
}

const avatars: Avatar[] = [
  { src: Vuong1, alt: "Soc Son, Ha Noi 2023" },
  { src: Vuong2, alt: "Moc Chau, Son La 2023" },
  { src: Vuong3, alt: "Kunming, Yunnan 2024" },
  { src: Vuong4, alt: "Quy Nhon, Binh Dinh 2024" },
];

export default avatars;
