const facts: IFact[] = [
  {
    count: 5,
    title: "Years of Web",
  },
  {
    count: 23,
    title: "Deployed Websites",
  },
  {
    count: 17,
    title: "Github Repos",
  },
  {
    count: 11,
    title: "Projects for Community",
  },
  {
    count: 12,
    title: "Delivered Projects",
  },
  {
    count: 1,
    title: "Day of Delivery for Web Building",
  },
  {
    count: 1,
    title: "Week of Delivery for Web Development",
  },
  {
    percent: 100,
    title: "Completed Orders",
  },
  {
    count: 4.7,
    title: "Average Rating",
  },
];

export default facts;