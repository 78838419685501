import { LucideIcon } from "lucide-react";

interface ITabProps {
  icon?: LucideIcon;
  label: string;
  tabId: string;
  children: React.ReactNode;
}

/**
 * A single tab component that will be used as a child of a Tabs component.
 *
 * @param icon - An optional icon to be displayed in the tab.
 * @param label - The label of the tab that will be displayed in the tab header.
 * @param tabId - The id of the tab. This should be unique for each tab of the same Tabs component.
 * @param children - The content of the tab. This will be rendered when the tab is active.
 */
export default function Tab({
  icon,
  label,
  tabId,
  children,
}: ITabProps): JSX.Element {
  return (
    <div
      className="p-4 rounded-lg border border-white dark:border-gray-900"
      key={tabId}
    >
      {children}
    </div>
  );
}
