import { FacebookIcon, GithubIcon, LucideIcon, LinkedinIcon, MailIcon, PhoneIcon, MessageSquareIcon, AtSignIcon, LibraryIcon, SendIcon } from "lucide-react";

interface Link {
  icon: LucideIcon;
  name: string;
  url: string;
}

const links: Link[] = [
  { icon: GithubIcon, name: "GitHub", url: "https://github.com/xoaicc" },
  { icon: LinkedinIcon, name: "LinkedIn", url: "https://www.linkedin.com/in/xoaicc/" },
  { icon: FacebookIcon, name: "Facebook", url: "https://www.facebook.com/vuong.xoaicc" },
  { icon: SendIcon, name: "Telegram", url: "https://t.me/xoaicc" },
  { icon: AtSignIcon, name: "Thread", url: "https://www.threads.net/@xoaicc" },
  { icon: LibraryIcon, name: "AllWeKnow", url: "https://allweknow.vn/user/xoaicc" },
  { icon: MessageSquareIcon, name: "Zalo", url: "https://zalo.me/0383398867" },
  { icon: MailIcon, name: "Email", url: "mailto:xoaicc.work@gmail.com" },
  { icon: PhoneIcon, name: "Phone", url: "tel:+84383398867" },
];

export default links;
