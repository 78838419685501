import Hr from "./Hr";
import AnimatedText from "./AnimatedText";

interface ISectionProps {
  id?: string;
  moreClass?: string;
  delay?: number;
  title: string;
  description?: string;
  children: React.ReactNode;
}

/**
 * A Section component that renders a section with a title, description and
 * horizontal rule, and also accepts children components.
 *
 * @param {ISectionProps} props - The props for the component.
 * @param {string} [props.id] - The id of the section.
 * @param {string} [props.moreClass] - Additional class names for the section.
 * @param {number} [props.delay] - The delay for the animated title.
 * @param {string} props.title - The title of the section.
 * @param {string} [props.description] - The description of the section.
 * @param {React.ReactNode} props.children - The children components of the section.
 * @returns {JSX.Element} - The JSX element of the Section component.
 */
export default function Section({
  id,
  moreClass,
  delay,
  title,
  description,
  children,
}: ISectionProps): JSX.Element {
  return (
    <section id={id} className={`space-y-12 ${moreClass}`}>
      <div className="space-y-6 text-center">
        <AnimatedText text={title} delay={delay} />
        <p className="i-leading">{description}</p>
        <Hr />
      </div>
      {children}
    </section>
  );
}
