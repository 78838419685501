const workingInfos: { label: string; value: string }[] = [
  { label: "Working location", value: "Hanoi, Vietnam" },
  {
    label: "Working hours",
    value: "up to 12 hours/day & 70 - 80 hours/week",
  },
  { label: "Working style", value: "Remote, Hybrid, Onsite" },
];

export default workingInfos;
