/**
 * Formats a number into a more readable string using suffixes.
 *
 * Converts large numbers into a string representation with a suffix,
 * such as 'B+' for billions, 'M+' for millions, and 'K+' for thousands.
 * If the number is less than 1000, it returns the number as a string without modification.
 *
 * @param num - The number to be formatted.
 * @returns A formatted string representation of the number.
 */
export default function formatNumber(num: number): string {
  if (num >= 1e9) {
    return (num / 1e9).toFixed(1).replace(/\.0$/, '') + 'B+';
  } else if (num >= 1e6) {
    return (num / 1e6).toFixed(1).replace(/\.0$/, '') + 'M+';
  } else if (num >= 1e3) {
    return (num / 1e3).toFixed(1).replace(/\.0$/, '') + 'K+';
  } else {
    return num.toString();
  }
}