import Footer from "components/Footer";

interface PagesLayoutProps {
  children: React.ReactNode;
}

export default function PagesLayout({ children }: PagesLayoutProps) {
  return (
    <div className="max-w-6xl mx-auto p-6">
      {children}
      <Footer />
    </div>
  );
}
